import { FC } from 'react';
import cn from 'classnames';
import { ReactComponent as ReadOnlyFolderIcon } from 'images/newIcons/read-only-folder.svg';

interface ShowAllStreamsButtonProps {
  isActive: boolean;
  onClick: (flag: boolean) => void;
}

export const ShowAllStreamsButton: FC<ShowAllStreamsButtonProps> = ({
  isActive,
  onClick,
}) => {
  const handleClickButton = () => {
    onClick(!isActive);
  };

  return (
    <div
      className={cn(
        'h-[20px] gap-[4px] flex flex-row group cursor-pointer justify-center items-center min-w-[145px] hover:text-bright_product transition',
        {
          'text-bright_product': isActive,
        }
      )}
      onClick={handleClickButton}
    >
      <span className="tpg-c2">Показать все эфиры</span>
      <ReadOnlyFolderIcon
        className={cn(
          'group-hover:[&>path]:fill-bright_product w-[20px] h-[20px] [&>path]:transition',
          {
            '[&>path]:fill-bright_product': isActive,
          }
        )}
      />
    </div>
  );
};

import { FC, MouseEvent, useRef, useState } from 'react';
import { EUserRole } from 'constants/profile';
import { useAppDispatch, useClickOutside } from 'hooks';
import { IAccountRole, IOption } from 'interfaces';
import { IClanPlayer } from 'interfaces/player';
import { roleOptions } from 'mock';
import { updateAccountRoleThunk } from 'store/slices/account/actions';
import { deleteClanMemberThunk } from 'store/slices/clan/actions';

import { DeleteModal } from 'components/DeleteModal';
import { Button, Select } from 'components/ui';

interface AccessTableProps extends IClanPlayer {
  accountId: string;
  highPriorityRoles: IAccountRole[];
  onRemoveAccess: (id: string) => void;
  onUpdateAccess: (value: IClanPlayer) => void;
  isAccessEditing: boolean;
  isNewPlayer?: boolean;
}

export const AccessTable: FC<AccessTableProps> = ({
  clanId,
  clanName,
  roleId,
  accountId,
  highPriorityRoles,
  onRemoveAccess,
  onUpdateAccess,
  isAccessEditing,
  isNewPlayer,
}) => {
  const roleInitialValue = roleOptions.find(
    (option) => option.value === roleId
  );

  const [selectedRole, setSelectedRole] = useState<IOption>(
    roleInitialValue || {
      value: '',
      label: '',
    }
  );

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const [isShowDeleteButton, setShowDeleteButton] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const clan = highPriorityRoles.find((role) => role.clan_id === clanId);

  const isAdminForPlayer =
    clan?.role_name === EUserRole.ADMIN || clan?.role_name === EUserRole.ROOT;

  const dispatch = useAppDispatch();

  useClickOutside<HTMLDivElement, void>(containerRef, () =>
    setShowDeleteButton(false)
  );

  const handleRightClick = (e: MouseEvent) => {
    e.preventDefault();

    setShowDeleteButton(true);
  };

  const handleSelectRole = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setSelectedRole(value);

      if (!isNewPlayer) {
        dispatch(
          updateAccountRoleThunk({
            account_id: accountId,
            clan_id: String(clanId),
            role_id: value.value,
          })
        );
      }

      onUpdateAccess({
        clanId: String(clanId),
        clanName,
        roleId: value.value,
        roleName: value.label,
      });
    }
  };

  const handleOpenModal = (event: MouseEvent) => {
    event.stopPropagation();

    setOpenDeleteModal(true);
  };

  const handleCloseModal = () => {
    setOpenDeleteModal(false);
  };

  const handleRemoveAccess = () => {
    if (!isNewPlayer) {
      dispatch(
        deleteClanMemberThunk({
          clanId: String(clanId),
          memberId: accountId,
          isAddedFromAccount: true,
        })
      );
    }

    onRemoveAccess(String(clanId));

    handleCloseModal();
  };

  return (
    <>
      <div
        ref={containerRef}
        className="border border-solid rounded-[10px] border-dark_product"
        onContextMenu={handleRightClick}
      >
        <div className="relative flex flex-col px-[16px] py-[10px] h-[59px]">
          <span className="tpg-c2 text-tpg_light">Подразделение</span>
          <span>{clanName}</span>
          {isShowDeleteButton &&
            ((isAdminForPlayer && isAccessEditing) || isNewPlayer) && (
              <Button
                className="absolute left-[140px] top-[15px] rounded-[10px] border border-solid border-tpg_light bg-ultrablack px-[16px] py-[6px] text-tpg_base hover:bg-dark_product"
                title="Удалить"
                onClick={handleOpenModal}
              />
            )}
        </div>
        {(isAdminForPlayer && isAccessEditing) || isNewPlayer ? (
          <Select
            value={selectedRole}
            options={roleOptions}
            onSelect={handleSelectRole}
            placeholder="Роль"
            className="cursor-pointer pl-[16px] rounded-b-[10px] border-t border-solid border-t-dark_product"
          />
        ) : (
          <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
            <span className="tpg-c2 text-tpg_light">Роль</span>
            <span className="tpg-c1 pointer-events-none">
              {selectedRole.label || 'Не выбрана'}
            </span>
          </div>
        )}
      </div>
      {isOpenDeleteModal && (
        <DeleteModal
          title="Вы уверены, что хотите удалить доступ?"
          onClose={handleCloseModal}
          onRemove={handleRemoveAccess}
        />
      )}
    </>
  );
};

import { FC, useEffect, useMemo, useState } from 'react';
import { getClans } from 'api/clans';
import { errorMessages } from 'constants/messages';
import { ReactComponent as PlusIcon } from 'images/newIcons/plus.svg';
import { IAccountRole, IOption } from 'interfaces';
import { IClanPlayer } from 'interfaces/player';
import { rolesValuesMap } from 'types/player';

import { filterOptionsByUniqueClan, getWordDayForm, notify } from 'utils';

import { AccessTable } from './components/AccessTable';
import { AddAccessCard } from './components/AddAccessCard';

interface AccessListProps {
  clans: IClanPlayer[];
  accountId: string;
  highPriorityRoles: IAccountRole[];
  onAddAccess: (value: IClanPlayer) => void;
  onRemoveAccess: (id: string) => void;
  onUpdateAccess: (value: IClanPlayer) => void;
  isAccessEditing: boolean;
  isBanned: boolean;
  isNewPlayer?: boolean;
}

export const AccessList: FC<AccessListProps> = ({
  clans,
  accountId,
  highPriorityRoles,
  onAddAccess,
  onRemoveAccess,
  onUpdateAccess,
  isAccessEditing,
  isBanned,
  isNewPlayer,
}) => {
  const [isNewAccess, setNewAccess] = useState(false);
  const [clanOptions, setClanOptions] = useState<IOption[]>([]);

  const firteredClans = useMemo(
    () => clans.filter((clan) => clan.roleId !== rolesValuesMap.root),
    [clans]
  );

  useEffect(() => {
    const getClansData = async () => {
      try {
        const data = await getClans();

        const options = data.map(({ id, name }) => ({
          value: String(id),
          label: name,
        }));

        const clanOptions = filterOptionsByUniqueClan(options, clans);

        setClanOptions(clanOptions);
      } catch (error) {
        notify.error(errorMessages.GET_CLANS);
      }
    };

    getClansData();
  }, [clans]);

  const wordForm = getWordDayForm(firteredClans?.length, [
    'доступ',
    'доступа',
    'доступов',
  ]);

  const handleOpenCardAccess = () => {
    setNewAccess(true);
  };

  const handleClose = () => {
    setNewAccess(false);
  };

  return (
    <div className="w-6/12 min-h-full overflow-auto px-[16px]">
      <div className="flex flex-row justify-between bg-ultrablack py-[16px] sticky top-0 z-[2]">
        <span>{`${firteredClans?.length} ${wordForm}`}</span>
        <div
          className="group cursor-pointer flex items-center justify-center transition"
          onClick={handleOpenCardAccess}
        >
          {!!clanOptions.length &&
            (isAccessEditing || isNewPlayer) &&
            !isBanned && (
              <PlusIcon className="scale-125 group-hover:[&>g>path]:fill-bright_product [&>g>path]:transition" />
            )}
        </div>
      </div>
      <div className="flex flex-col gap-[16px] pb-[16px]">
        {isNewAccess && (
          <AddAccessCard
            accountId={accountId}
            clanOptions={clanOptions}
            onAddAccess={onAddAccess}
            onClose={handleClose}
            isNewPlayer={isNewPlayer}
          />
        )}
        {firteredClans?.map((clan) => (
          <AccessTable
            key={clan.clanId}
            accountId={accountId}
            onRemoveAccess={onRemoveAccess}
            onUpdateAccess={onUpdateAccess}
            isNewPlayer={isNewPlayer}
            isAccessEditing={isAccessEditing}
            highPriorityRoles={highPriorityRoles}
            {...clan}
          />
        ))}
      </div>
    </div>
  );
};

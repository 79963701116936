import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LiveKitRoom } from '@livekit/components-react';
import { SEPARATOR } from 'constants/livekit';
import { useAppDispatch, useAppSelector } from 'hooks';
import { roomSelector } from 'store/slices/room';
import {
  getClanRoomsThunk,
  getLiveKitTokenThunk,
} from 'store/slices/room/actions';

import { ViewerPlayer } from 'components/StreamPlayerContainer/components/ViewerPlayer';
import { Button } from 'components/ui';
import { Loader } from 'components/ui/Loader';
import { Tag } from 'components/ui/Tag';

export const StreamScreen = () => {
  const { error, activeRoomsByClanId } = useAppSelector(roomSelector);

  const { id: liveKitRoomName } = useParams();

  const { state } = useLocation();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const room = activeRoomsByClanId?.find(
    (room) => room.livekitName === liveKitRoomName
  );

  useEffect(() => {
    dispatch(getClanRoomsThunk(state.clanId));
  }, [state.clanId]);

  useEffect(() => {
    if (liveKitRoomName) {
      const [clanId, roomName] = liveKitRoomName.split(SEPARATOR);

      dispatch(getLiveKitTokenThunk({ clanId, roomName }));
    }
  }, [liveKitRoomName]);

  if (!room?.token || !liveKitRoomName)
    return (
      <Loader className="h-[calc(100%-24px)] w-[calc(100%-24px)] flex justify-center items-center" />
    );

  if (error)
    return (
      <div className="m-[24px] flex flex-col gap-[16px]">
        <h2>Такого эфира не существует</h2>
        <Button title="Назад" onClick={() => navigate(-1)} />
      </div>
    );

  return (
    <div className="m-[24px] flex flex-col gap-[16px]">
      <LiveKitRoom
        token={room.token}
        serverUrl={process.env.REACT_APP_LIVEKIT_API}
      >
        <ViewerPlayer />
      </LiveKitRoom>
      <Tag label={room.publisherName} />
    </div>
  );
};

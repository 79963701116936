import { FC } from 'react';
import cn from 'classnames';
import { useBreakPoint } from 'hooks';
import { IRoomParams } from 'interfaces';

import { Room } from './Room';

interface PanelProps {
  isOpenFullScreen: boolean;
  onBack: () => void;
  rooms: IRoomParams[];
  clanId?: string;
}

export const Panel: FC<PanelProps> = ({
  isOpenFullScreen,
  onBack,
  rooms,
  clanId: id,
}) => {
  const breakPoint = useBreakPoint();

  const gridSize =
    breakPoint === 'mobile' || rooms.length === 1
      ? 'grid-cols-1'
      : rooms.length === 2
      ? 'grid-cols-2'
      : 'grid-cols-3';

  return (
    <div className="flex flex-col gap-[16px]">
      {isOpenFullScreen && (
        <div
          onClick={onBack}
          className="flex flex-row gap-[5px] absolute left-[50%] translate-x-[-50%] top-0 py-[6px] px-[16px] z-[1] bg-ultrablack/80 rounded-b-[5px]"
        >
          <span className="tpg-c2 text-bright_product">esc</span>
          <span className="tpg-c2">вернуть обратно</span>
        </div>
      )}
      <div className={cn(`grid ${gridSize} gap-[8px] w-full`, {})}>
        {rooms?.length ? (
          rooms.map(
            ({
              livekitName,
              publisherName,
              token,
              isHidden,
              clanId,
              clanName,
            }) =>
              !isHidden && (
                <Room
                  key={livekitName}
                  livekitName={livekitName}
                  publisherName={publisherName}
                  token={token}
                  clanId={clanId || id}
                  clanName={clanName}
                />
              )
          )
        ) : (
          <span className="tpg-h4">0 в эфире</span>
        )}
      </div>
    </div>
  );
};

import { FC } from 'react';
import cn from 'classnames';
import { useAppSelector, useBreakPoint } from 'hooks';
import { roomSelector } from 'store/slices/room/selectors';

import { CopyIcon } from 'components/CopyIcon';
import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal';

interface CopyModalProps {
  onClose: () => void;
  onClick: () => void;
}

export const CopyModal: FC<CopyModalProps> = ({ onClose, onClick }) => {
  const { url, streamKey } = useAppSelector(roomSelector);

  const breakPoint = useBreakPoint();

  return (
    <Modal onClose={onClose} isBlurred containerClassName="!mt-0">
      <div
        className={cn(
          'flex flex-col gap-[24px] p-[48px] rounded-[10px] bg-dark border border-solid border-tpg_light items-center min-w-[460px]',
          {
            'min-w-[320px]': breakPoint === 'mobile',
          }
        )}
      >
        {!url && !streamKey ? (
          <div>Произошла ошибка с подключением</div>
        ) : (
          <>
            <div className="tpg-h4">Скопируйте данные</div>
            <div className="flex flex-row justify-between items-center w-full py-[10px] px-[16px] rounded-[5px] bg-light tpg-b2 text-bright_product">
              <span>{url}</span>
              <CopyIcon value={url} />
            </div>
            <div className="flex h-[44px] flex-row justify-between items-center w-full py-[10px] px-[16px] rounded-[5px] bg-light">
              <span className="h-[12px]">{streamKey}</span>
              <CopyIcon value={streamKey} />
            </div>
            <div>Полная ссылка</div>
            <div className="flex h-[44px] flex-row justify-between items-center w-full py-[10px] px-[16px] rounded-[5px] bg-light">
              <span className="h-[12px]">{`${url}/${streamKey}`}</span>
              <CopyIcon value={`${url}/${streamKey}`} />
            </div>
            <Button
              onClick={onClick}
              title="Далее"
              className="w-full py-[13px]"
            />
          </>
        )}
      </div>
    </Modal>
  );
};

import { FC, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LiveKitRoom } from '@livekit/components-react';
import { EUserRole } from 'constants/profile';
import { useAppDispatch, useAppSelector, useRoles } from 'hooks';
import { TSaveRecordParams } from 'interfaces/record';
import { accountSelector } from 'store/slices/account/selectors';
import {
  deleteRoomIngressThunk,
  deleteRoomThunk,
} from 'store/slices/room/actions';
import { roomSelector } from 'store/slices/room/selectors';

import { Loader } from 'components/ui/Loader';

import { StreamController } from './StreamController';

interface IStreamLaunchProps {
  username: string;
}

export const StreamLaunch: FC<IStreamLaunchProps> = ({ username }) => {
  const { roles } = useAppSelector(accountSelector);

  const { selectedRoom, pending } = useAppSelector(roomSelector);

  const dispatch = useAppDispatch();

  const saveRecordParams = useRef<TSaveRecordParams>();

  const { state: clanHost }: { state?: string } = useLocation();

  const navigate = useNavigate();

  const getClanHost = useRoles([EUserRole.ADMIN, EUserRole.USER], roles);

  const clanId = useMemo(() => {
    if (!clanHost) return getClanHost();

    return clanHost;
  }, [clanHost, roles]);

  const roomName = `${username}_stream`;

  useEffect(
    () => () => {
      if (clanId) {
        dispatch(
          deleteRoomThunk({
            clanId,
            roomName,
            params: saveRecordParams.current || { save_recording: false },
          })
        );

        dispatch(
          deleteRoomIngressThunk({
            clanId,
            roomName,
          })
        );
      }
    },
    [clanId]
  );

  const handleSave = (params?: TSaveRecordParams) => {
    saveRecordParams.current = params;

    navigate(-1);
  };

  if (pending)
    return (
      <Loader className="h-[calc(100%-24px)] w-[calc(100%-24px)] flex justify-center items-center" />
    );

  return (
    <LiveKitRoom
      className="flex flex-col gap-[16px] max-w-[650px] m-auto"
      token={selectedRoom?.token}
      serverUrl={process.env.REACT_APP_LIVEKIT_API}
    >
      <StreamController onSave={handleSave} />
    </LiveKitRoom>
  );
};

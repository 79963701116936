import { EUserRole } from 'constants/profile';
import { IPlayer } from 'interfaces/player';

export enum EPlayerRole {
  PLAYER = 'Источник',
  MODERATOR = 'Потребитель',
  OBSERVER = 'Наблюдатель',
  ADMIN = 'Организатор',
}

export const rolesTranslateMap = {
  [EUserRole.ADMIN]: 'Организатор',
  [EUserRole.MODERATOR]: 'Потребитель',
  [EUserRole.USER]: 'Источник',
  [EUserRole.ROOT]: 'Администратор',
};

export const rolesValuesMap = {
  [EUserRole.ADMIN]: '00000000000000000000000006',
  [EUserRole.MODERATOR]: '00000000000000000000000005',
  [EUserRole.USER]: '00000000000000000000000004',
  [EUserRole.ROOT]: '00000000000000000000000001',
};

export const rolesReverseMap: { [key: string]: EUserRole } = {
  ['00000000000000000000000006']: EUserRole.ADMIN,
  ['00000000000000000000000005']: EUserRole.MODERATOR,
  ['00000000000000000000000004']: EUserRole.USER,
};

export type TPlayerTable = Omit<IPlayer, 'login' | 'password'>;

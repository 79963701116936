import { FC } from 'react';
import { ReactComponent as FullSizeIcon } from 'images/newIcons/fullSize.svg';

interface FullScreenButtonProps {
  isActive: boolean;
  onClick: (flag: boolean) => void;
}

export const FullScreenButton: FC<FullScreenButtonProps> = ({
  onClick,
  isActive,
}) => {
  const handleClickButton = () => {
    onClick(!isActive);
  };

  return (
    <div
      className="h-[20px] gap-[4px] flex flex-row group cursor-pointer justify-center items-center min-w-[145px] hover:text-bright_product transition"
      onClick={handleClickButton}
    >
      <span className="tpg-c2">На полный экран</span>
      <FullSizeIcon className="group-hover:[&>g>path]:fill-bright_product w-[18px] h-[18px] [&>g>path]:transition" />
    </div>
  );
};

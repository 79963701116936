import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SEPARATOR } from 'constants/livekit';
import { LP_INTERVAL_MS } from 'constants/profile';
import { useAppDispatch, useAppSelector } from 'hooks';
import { roomActions, roomSelector } from 'store/slices/room';
import {
  getClanRoomsThunk,
  getLiveKitTokenThunk,
} from 'store/slices/room/actions';

import { Panel } from './Panel';

interface ClanStreamsPanelProps {
  isOpenFullScreen: boolean;
  onBack: () => void;
}

export const ClanStreamsPanel: FC<ClanStreamsPanelProps> = ({
  isOpenFullScreen,
  onBack,
}) => {
  const { activeRoomsByClanId } = useAppSelector(roomSelector);

  const { id: clanId } = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const getRooms = () => {
      if (clanId) {
        dispatch(getClanRoomsThunk(clanId));

        dispatch(roomActions.selectActiveRoomsByClanId({ clanId }));
      }
    };

    getRooms();

    const roomsInterval = setInterval(getRooms, LP_INTERVAL_MS);

    return () => {
      clearInterval(roomsInterval);
    };
  }, [clanId]);

  useEffect(() => {
    if (activeRoomsByClanId?.length) {
      activeRoomsByClanId.forEach((room) => {
        const [clanId, roomName] = room.livekitName.split(SEPARATOR);

        if (!room.token) {
          dispatch(getLiveKitTokenThunk({ clanId, roomName }));
        }
      });
    }
  }, [activeRoomsByClanId]);

  return (
    <Panel
      isOpenFullScreen={isOpenFullScreen}
      onBack={onBack}
      rooms={activeRoomsByClanId}
      clanId={clanId}
    />
  );
};

import { useEffect, useState } from 'react';

export type TBreakPoint = 'mobile' | 'tablet' | 'tablet-landscape' | 'desktop';

const getBreakPoint = (width: number): TBreakPoint => {
  switch (true) {
    case width <= 425:
      return 'mobile';
    case width >= 426 && width <= 768:
      return 'tablet';
    case width >= 769 && width <= 1024:
      return 'tablet-landscape';
    case width >= 1025 && width <= 1440:
    default:
      return 'desktop';
  }
};

export const useBreakPoint = () => {
  const [breakPoint, setBreakPoint] = useState<TBreakPoint>('mobile');

  useEffect(() => {
    setBreakPoint(getBreakPoint(window.innerWidth));
  }, []);

  useEffect(() => {
    const getInnerWidth = () => {
      setBreakPoint(getBreakPoint(window.innerWidth));
    };

    window.addEventListener('resize', getInnerWidth);

    return () => window.removeEventListener('resize', getInnerWidth);
  }, []);

  return breakPoint;
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  banAccount,
  getAccount,
  updateAccount,
  updateAccountRole,
} from 'api/account';
import { changeEmail, changePassword } from 'api/auth';
import { IAccountThunkData } from 'api/types/account';
import { AxiosResponse } from 'axios';
import { errorMessages } from 'constants/messages';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import { IPlayer } from 'interfaces/player';
import { ID } from 'types';

import { createThunk, notify } from 'utils';

import { getClanThunk } from '../clan/actions';
import { getAllPlayersThunk } from '../player/actions';

export const getAccountThunk = createThunk<IPlayer, ID>(
  `${reducersNames.ACCOUNT}/${asyncActionsNames.GET_ACCOUNT}`,
  getAccount,
  { errorMessage: errorMessages.GET_ACCOUNT }
);

export const updateAccountThunk = createAsyncThunk<
  AxiosResponse<IAccountThunkData>,
  IAccountThunkData
>(
  `${reducersNames.ACCOUNT}/${asyncActionsNames.UPDATE_ACCOUNT}`,
  async (request, thunkAPI) => {
    const {
      id,
      name,
      clanId,
      computer_id,
      metadata,
      password,
      isNewPassword,
      isAddedFromAccount,
      isNewEmail,
      email,
    } = request;

    try {
      const data = await updateAccount({
        id,
        name,
        computer_id,
        metadata,
      });

      if (computer_id && clanId) {
        thunkAPI.dispatch(getClanThunk(String(clanId)));
      }

      if (isAddedFromAccount) {
        if (isNewPassword) {
          thunkAPI.dispatch(
            updateAccountPasswordThunk({
              id: String(id),
              password: String(password),
              password2: String(password),
            })
          );
        }

        if (isNewEmail && email) {
          thunkAPI.dispatch(
            updateAccountEmailThunk({
              id: String(id),
              email,
              current_password: String(password),
            })
          );
        }

        thunkAPI.dispatch(getAllPlayersThunk());
      }

      return data;
    } catch (error) {
      notify.error(errorMessages.UPDATE_ACCOUNT);

      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateAccountRoleThunk = createThunk(
  `${reducersNames.ACCOUNT}/${asyncActionsNames.UPDATE_ACCOUNT_ROLE}`,
  updateAccountRole,
  { errorMessage: errorMessages.UPDATE_ACCOUNT }
);

export const updateAccountEmailThunk = createThunk(
  `${reducersNames.ACCOUNT}/${asyncActionsNames.UPDATE_ACCOUNT_EMAIL}`,
  changeEmail,
  { errorMessage: errorMessages.UPDATE_ACCOUNT }
);

export const updateAccountPasswordThunk = createThunk(
  `${reducersNames.ACCOUNT}/${asyncActionsNames.UPDATE_ACCOUNT_PASSWORD}`,
  changePassword,
  { errorMessage: errorMessages.UPDATE_ACCOUNT }
);

export const banAccountThunk = createAsyncThunk<AxiosResponse<string>, string>(
  `${reducersNames.ACCOUNT}/${asyncActionsNames.DELETE_ACCOUNT}`,
  async (request, thunkAPI) => {
    try {
      const data = await banAccount(request).then(async (res) => {
        await thunkAPI.dispatch(getAllPlayersThunk());

        return res;
      });

      return data;
    } catch (error) {
      notify.error(errorMessages.DELETE_ACCOUNT);

      return thunkAPI.rejectWithValue(error);
    }
  }
);

import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { roleWeights } from 'constants/profile';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEscapeDown } from 'hooks/useEscapeDown';
import { authSelector, clansSelector } from 'store';
import { getClansThunk } from 'store/slices/clan/actions';

import { AllClansStreamsPanel } from './AllClansStreamsPanel';
import { ClanStreamsPanel } from './ClanStreamsPanel';

interface StreamsPanelScreenProps {
  isOpenFullScreen: boolean;
  onClosePanel: (flag: boolean) => void;
  onCloseFullScreen: (flag: boolean) => void;
  onShowAllStream: (flag: boolean) => void;
  isShowAllStream?: boolean;
  isClansPage?: boolean;
}

export const StreamsPanelScreen: FC<StreamsPanelScreenProps> = ({
  isOpenFullScreen,
  onClosePanel,
  onCloseFullScreen,
  onShowAllStream,
  isShowAllStream,
  isClansPage,
}) => {
  const { clans } = useAppSelector(clansSelector);

  const { role } = useAppSelector(authSelector);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  useEscapeDown(() => onCloseFullScreen(false));

  useEffect(() => {
    dispatch(getClansThunk());

    return () => {
      onClosePanel(false);
      onCloseFullScreen(false);
      onShowAllStream(true);
    };
  }, []);

  useEffect(() => {
    if (role && roleWeights[role] >= roleWeights.moderator) {
      if (isClansPage && !isShowAllStream && clans.length) {
        navigate(`/clan/${clans[0].id}`);
      }

      if (isShowAllStream || (isOpenFullScreen && !isClansPage)) {
        navigate('/clans');
      }
    }
  }, [isClansPage, isShowAllStream, clans, roleWeights, role]);

  const handleClickBack = () => {
    onCloseFullScreen(false);
  };

  return isShowAllStream ? (
    <AllClansStreamsPanel
      isOpenFullScreen={isOpenFullScreen}
      onBack={handleClickBack}
    />
  ) : (
    <ClanStreamsPanel
      isOpenFullScreen={isOpenFullScreen}
      onBack={handleClickBack}
    />
  );
};

import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IClan } from 'interfaces/clan';
import { ITreeRooms } from 'interfaces/room';
import { clansSelector } from 'store/slices';
import { getClansThunk } from 'store/slices/clan/actions';
import { roomActions, roomSelector } from 'store/slices/room';

import { TextInput } from 'components/ui';
import { filterTreeRoomsBySearch } from 'utils';

export const FilterStreams = () => {
  const [searchValue, setSearchValue] = useState('');
  const [initialRooms, setInitialRooms] = useState<ITreeRooms[]>([]);
  const [filteredRooms, setFilteredRooms] = useState<ITreeRooms[]>([]);

  const { clans } = useAppSelector(clansSelector);

  const { rooms } = useAppSelector(roomSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getClansThunk());

    return () => {
      setSearchValue('');
    };
  }, []);

  useEffect(() => {
    const treeRooms = clans.reduce(
      (acc: ITreeRooms[], clan: IClan): ITreeRooms[] => {
        const activeRooms = rooms.get(clan.id)?.active;

        if (activeRooms?.length) {
          acc.push({
            clanName: clan.name,
            clanId: String(clan.id),
            rooms: activeRooms,
          });
        }

        return acc;
      },
      []
    );

    setFilteredRooms(treeRooms);
    setInitialRooms(treeRooms);
  }, [clans, rooms]);

  const handleChangeSearch = (value: string) => {
    const filteredTreeRooms = filterTreeRoomsBySearch(initialRooms, value);

    setFilteredRooms(filteredTreeRooms);

    setSearchValue(value);
  };

  const handleRevealRoom = (
    livekitName: string,
    clanId: string,
    isHidden?: boolean
  ) => {
    if (isHidden) {
      dispatch(
        roomActions.roomHideController({ livekitName, clanId, isHidden: false })
      );
    }
  };

  return (
    <div>
      <TextInput
        onChange={handleChangeSearch}
        value={searchValue}
        placeholder="Введите пользователя или подразделение"
        searchIcon
        inputClassName="!rounded-t-[10px] !rounded-b-none !py-[9px] !pr-[12px] !pl-[28px] !border-none"
        className="[&>div>svg]:!ml-[6px] [&>div>svg]:!w-5 [&>div>svg]:!h-5"
        size="m"
      />
      <div className="max-h-[150px] mb-[5px] overflow-auto">
        <div className="flex flex-col px-[10px] pt-[10px] gap-[5px]">
          {filteredRooms.length ? (
            filteredRooms?.map(({ rooms, clanName, clanId }) => (
              <div key={clanId} className="flex flex-col gap-[5px]">
                <span className="tpg-c2 text-tpg_base">{clanName}</span>
                {rooms.map(({ publisherName, isHidden, livekitName }) => (
                  <span
                    key={publisherName}
                    onClick={() =>
                      handleRevealRoom(livekitName, clanId, isHidden)
                    }
                    className={cn(
                      'cursor-pointer ml-[15px] tpg-c2 text-bright_product',
                      {
                        'text-tpg_base': isHidden,
                      }
                    )}
                  >
                    {publisherName}
                  </span>
                ))}
              </div>
            ))
          ) : (
            <span className="tpg-c2 text-tpg_base">0 в эфире</span>
          )}
        </div>
      </div>
    </div>
  );
};

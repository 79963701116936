import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AddButton } from 'components/AddButton';
import { SwitchWithLabel } from 'components/SwitchWithLabel';

export const RightHeaderContent = () => {
  const [isOn, setOn] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const isShowPlayers = searchParams.get('filter') === 'players';

  useEffect(() => {
    if (isShowPlayers) {
      setOn(true);
    }

    if (!searchParams.get('filter')) {
      setOn(false);
    }
  }, [searchParams, isShowPlayers]);

  const handleChange = () => {
    setOn(!isOn);

    setSearchParams((prev) => {
      prev.set('filter', `${!isOn ? 'players' : 'clans'}`);

      return prev;
    });
  };

  const handleAddClan = () => {
    navigate('/map');
  };

  const handleAddPlayer = () => {
    navigate('/admin-panel/player/new');
  };

  return (
    <div className="flex flex-row gap-[16px]">
      <AddButton
        label={
          isShowPlayers ? 'Добавить пользователя' : 'Добавить подразделение'
        }
        onClick={isShowPlayers ? handleAddPlayer : handleAddClan}
      />
      <SwitchWithLabel
        label="Список пользователей"
        checked={isOn}
        onChange={handleChange}
      />
    </div>
  );
};

import { FC } from 'react';

interface IMoreButtonProps {
  onClick: () => void;
  count?: number;
}

export const MoreButton: FC<IMoreButtonProps> = ({ count, onClick }) => (
  <div
    className="w-max max-h-[24px] flex gap-[4px] items-center px-[16px] cursor-pointer 
    py-[5px] bg-ultrablack hover:bg-dark_product rounded-[5px] transition-colors border-solid border-2 border-dark_product"
    onClick={onClick}
  >
    <span className="tpg-c2">{count ? 'Больше' : 'Скрыть'}</span>
    {count && <span className="tpg-c2 text-tpg_base">{`${count}`}</span>}
  </div>
);

import {
  IMediaFile,
  IOption,
  ISelectOption,
  ITag,
  ITreeRooms,
} from 'interfaces';
import { IClanPlayer } from 'interfaces/player';
import { IRecord } from 'interfaces/record';

import { isVideo } from './monitoring';

export * from './coordinates';
export * from './geospoof';
export * from './helpers/date';
export * from './helpers/string';
export * from './monitoring';
export * as notify from './notifications';
export * from './store';
export * from './tokenStore';

export const getTreeSelectOptions = <
  T extends {
    title: string;
    id: number | string;
    sublayers: T[];
    subobjects: unknown[];
    checked?: boolean;
  }
>(
  list: T[],
  editable: T | null
): ISelectOption[] => {
  const options = list.map(
    ({ title, id, sublayers, checked }) =>
      ({
        label: title,
        value: id,
        checked,
        children: sublayers?.length
          ? getTreeSelectOptions(sublayers, editable)
          : null,
      } as ISelectOption)
  );

  if (editable) {
    if (options.some((item: ISelectOption) => item.value === editable.id)) {
      return options.filter((option) => option.value !== editable.id);
    }

    const findParent = (
      acc: ISelectOption | null,
      item: ISelectOption
    ): ISelectOption | null => {
      if (
        item.children?.some(
          (child: ISelectOption) => child.value === editable.id
        )
      ) {
        item.children = item.children.filter(
          (option) => option.value !== editable.id
        );

        return null;
      }

      if (item.children) return item.children.reduce(findParent, acc);

      return acc;
    };

    options.reduce(findParent, null);
  }

  return options;
};

export const getFilteredOptions = (
  list: ISelectOption[],
  value: string
): ISelectOption[] => {
  if (value) {
    return list.filter((item: ISelectOption) => {
      if (item.children) {
        item.children = getFilteredOptions(item.children, value);

        if (item.children.length) {
          return true;
        }
      }

      return item.label.toLowerCase().includes(value.toLowerCase());
    });
  }

  return list;
};

export const getMediaFiles = (
  files: Array<string | Omit<IMediaFile, 'type'> | IMediaFile>
): IMediaFile[] => {
  if (typeof files === 'string') {
    return [
      {
        url: files,
        type: isVideo(files) ? 'video' : 'image',
      },
    ];
  }

  if (files.length) {
    return files.map((item) => ({
      ...(typeof item === 'object' ? item : {}),
      url: typeof item === 'object' ? item.url : item,
      type: isVideo(
        (item as IMediaFile).file
          ? ((item as IMediaFile)?.file as File).name
          : typeof item === 'object'
          ? item.url
          : item
      )
        ? 'video'
        : 'image',
    }));
  }

  return [];
};

export const getMonospacedString = (value: string) => `\`${value}\``;

export const maskPassword = (password: string) => password.replace(/./g, '*');

export const getWordDayForm = (
  quantity = 0,
  words: [string, string, string]
) => {
  const string = quantity.toString();
  const lastChar = string.charAt(string.length - 1);

  switch (true) {
    case lastChar === '1' && !(quantity === 11):
      return words[0];
    case lastChar === '2' && !(quantity === 12):
    case lastChar === '3' && !(quantity === 13):
    case lastChar === '4' && !(quantity === 14):
      return words[1];

    default:
      return words[2];
  }
};

export const generatePassword = (length: number) => {
  const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const digits = '0123456789';

  const str = lowerCaseLetters + upperCaseLetters + digits;

  let password = '';

  for (let i = 1; i <= length; i++) {
    const char = Math.floor(Math.random() * str.length + 1);

    password += str.charAt(char);
  }

  return password;
};

export const filterOptionsByUniqueClan = (
  options: IOption[],
  players: IClanPlayer[]
) =>
  options.filter(
    (option) => !players.some((player) => player.clanId === option.value)
  );

export const mergeUniqueRecordTags = (records: IRecord[]) => {
  const uniqueTags = new Set<string>();

  records.forEach((record) => {
    if (record.tags) {
      record.tags.forEach((tag) => uniqueTags.add(tag));
    }
  });

  return Array.from(uniqueTags);
};

export const filterRecordBySelectedTags = (
  records: IRecord[],
  tags: ITag[]
) => {
  const str = tags.map((tag) => tag.title);

  return records.filter((record) => {
    if (Array.isArray(record.tags)) {
      return record.tags.some((item) => str.includes(item));
    }

    return false;
  });
};

export const getOptionsByFilteredTags = (options: IOption[], tags: string[]) =>
  tags.flatMap((tag) =>
    options.some((item) => tag.includes(item.label))
      ? [{ value: tag, label: tag }]
      : []
  );

export const filterTreeRoomsBySearch = (
  treeRooms: ITreeRooms[],
  searchValue: string
) =>
  treeRooms.filter(({ clanName, rooms }) => {
    if (clanName.includes(searchValue)) {
      return true;
    }

    if (Array.isArray(rooms)) {
      return rooms.some((room) => room.livekitName.includes(searchValue));
    }

    return false;
  });

export const isMobileDevice = () =>
  /Mobi|Android|iPhone/i.test(navigator.userAgent);

import { FC, ReactElement, ReactNode, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { navs } from 'constants/navs';
import { EUserRole } from 'constants/profile';
import { authRoutes, navigation } from 'constants/routes';
import { useAppDispatch, useAppSelector, useBreakPoint, useRoles } from 'hooks';
import { authActions, authSelector } from 'store';

import { CopyModal } from 'components/CopyModal';
import { ConfirmModal } from 'components/ui/Modal/ConfirmModal';

import HeaderLink from './HeaderLink';
import { StartStreamButton } from './StartStreamButton';

import './style.scss';

type TNavItem = {
  link: string;
  title: string;
  icon?: ReactElement;
  onClick?: () => void;
};

interface IHeaderProps {
  isPageControlPanel?: boolean;
  leftContent?: ReactNode;
  centralContent?: ReactNode;
  rightButton?: ReactNode;
  isStartStream?: boolean;
  resetOptions?: () => void;
}

export const Header: FC<IHeaderProps> = ({
  isPageControlPanel = true,
  leftContent,
  centralContent,
  rightButton,
  isStartStream,
  resetOptions,
}) => {
  const [isOpenModalLogout, setOpenModalLogout] = useState(false);

  const [isOpenModalAppStream, setOpenModalAppStream] = useState(false);

  const { role, roles } = useAppSelector(authSelector);

  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const getClanHost = useRoles([EUserRole.ADMIN, EUserRole.USER], roles);

  const breakPoint = useBreakPoint();

  const handleLogout = () => {
    dispatch(authActions.logout());
    setTimeout(navigate, 100, authRoutes.SIGN_IN);
  };

  const handleOpenModalAppStream = () => {
    setOpenModalAppStream(true);
  };

  const renderNavItem = (
    { link, title, icon, onClick }: TNavItem,
    idx: number
  ) => (
    <HeaderLink
      isActive={!!link && pathname.includes(link)}
      link={link}
      title={title}
      passiveIcon={icon || undefined}
      key={`link-${idx}`}
      onClick={onClick}
      containerClassName={breakPoint === 'mobile' ? 'px-[16px]' : ''}
    />
  );

  const handleClickLogo = () => {
    resetOptions?.();

    navigate(role ? navigation[role] : '/');
  };

  const handleStartStream = () => {
    const clanId = getClanHost();

    navigate('/stream-launch-app', {
      state: clanId,
    });

    setOpenModalAppStream(false);
  };

  const handleNavItem = (nav: TNavItem, idx: number) => {
    const userAgent = navigator.userAgent;

    if (nav.title === 'Админка' && /Mobi|Android|iPhone/i.test(userAgent)) {
      return;
    }

    return renderNavItem(nav, idx);
  };

  return (
    <>
      <div className="flex flex-col">
        <nav className="header flex justify-center">
          <div
            className="tpg-h3 cursor-pointer flex flex-row content-center"
            onClick={handleClickLogo}
          >
            <span>ASTRA</span>
            <span className="text-bright_product">S</span>
          </div>
          <div className="header__links">
            <div
              className={cn(
                'absolute left-1/2 -translate-x-1/2 flex flex-row',
                { '-translate-x-1/3': breakPoint === 'mobile' }
              )}
            >
              {role &&
                navs[role].map((nav, ind) =>
                  handleNavItem({ ...nav, onClick: resetOptions }, ind)
                )}
            </div>
          </div>
          <span
            className="cursor-pointer tpg-b1 text-tpg_base hover:text-bright_product transition"
            onClick={() => setOpenModalLogout(true)}
          >
            Выйти
          </span>
        </nav>
        {isPageControlPanel && (
          <div className="w-full h-[48px] flex flex-row items-center justify-between bg-dark sticky top-0 z-10 px-[24px]">
            {leftContent ? (
              leftContent
            ) : (
              //TODO:temporarily hidden
              // <TextInput
              //   value={searchQuery}
              //   placeholder="Начните поиск"
              //   searchIcon
              //   isReset
              //   className="w-full bg-dark max-w-[540px] xl:max-w-[300px]"
              //   theme="dark"
              //   onChange={(value) =>
              //     dispatch(streamActions.setQuerySearch(value))
              //   }
              // />
              <div />
            )}
            {centralContent && centralContent}
            {!rightButton && !isStartStream ? (
              <StartStreamButton onOpenModal={handleOpenModalAppStream} />
            ) : (
              !isStartStream && rightButton
            )}
            {rightButton && isStartStream && (
              <div className="flex flex-row gap-[16px]">
                {rightButton}
                <StartStreamButton onOpenModal={handleOpenModalAppStream} />
              </div>
            )}
          </div>
        )}
      </div>
      {isOpenModalLogout && (
        <ConfirmModal
          title="Вы уверены, что хотите выйти?"
          description=""
          confirmText="Да, я хочу выйти"
          onConfirm={handleLogout}
          onClose={() => {
            setOpenModalLogout(false);
          }}
        />
      )}
      {isOpenModalAppStream && (
        <CopyModal
          onClick={handleStartStream}
          onClose={() => {
            setOpenModalAppStream(false);
          }}
        />
      )}
    </>
  );
};

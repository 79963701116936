import { FC } from 'react';
import cn from 'classnames';
import { ReactComponent as CheckIcon } from 'images/icons/check.svg';
import { ReactComponent as TileIcon } from 'images/newIcons/tile.svg';

interface ShowTileButtonProps {
  isActive: boolean;
  onClick: (flag: boolean) => void;
}

export const ShowTileButton: FC<ShowTileButtonProps> = ({
  onClick,
  isActive,
}) => {
  const handleClickButton = () => {
    onClick(!isActive);
  };

  return (
    <div
      className={cn(
        'h-[20px] gap-[4px] flex flex-row group cursor-pointer justify-center items-center min-w-[145px] hover:text-bright_product transition',
        {
          'text-bright_product': isActive,
        }
      )}
      onClick={handleClickButton}
    >
      <span className="tpg-c2">Вывести плиткой</span>
      {isActive ? (
        <CheckIcon className="[&>path]:fill-bright_product w-[18px] h-[18px]" />
      ) : (
        <TileIcon className="group-hover:[&>path]:fill-bright_product w-[18px] h-[18px] [&>path]:transition" />
      )}
    </div>
  );
};

import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { clansSelector } from 'store';
import { getClansThunk } from 'store/slices/clan/actions';
import { roomSelector } from 'store/slices/room';

import { FilterSection } from 'components/FilterSection';
import { DateTag } from 'components/FilterSection/DateTag';

import { ClansStreamList } from './ClansStreamList';

export const ClansScreen = () => {
  const [streamDate, setStreamDate] = useState<(string | null)[]>([]);

  const { clans } = useAppSelector(clansSelector);
  const { rooms } = useAppSelector(roomSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getClansThunk());
  }, []);

  const activeRooms = Array.from(rooms)
    .flat()
    .flatMap((room) =>
      typeof room !== 'string' && room.active.length ? room.active : []
    );

  return (
    <div className="flex flex-col gap-[16px]">
      <div className="relative">
        <FilterSection
          title={`${activeRooms.length} в эфире`}
          streamDate={streamDate}
          setStreamDate={setStreamDate}
        />
        {!!streamDate.length && (
          <div className="absolute top-0 right-[24px] flex flex-col gap-[8px]">
            {!!streamDate.length && (
              <DateTag date={streamDate} setDate={setStreamDate} />
            )}
          </div>
        )}
      </div>
      {!!clans.length &&
        clans.map(({ id, name, work_area }) => (
          <ClansStreamList clan={name} id={id} place={work_area} key={id} />
        ))}
    </div>
  );
};

import { FC, useState } from 'react';
import { TSaveRecordParams } from 'interfaces/record';

import { ControllerRecordModal } from 'components/ControllerRecordModal';
import { Presence } from 'components/Presence';
import { Button } from 'components/ui';

interface StreamControllerProps {
  onSave: (params?: TSaveRecordParams) => void;
}

export const StreamController: FC<StreamControllerProps> = ({ onSave }) => {
  const [isOpenControllerModal, setOpenControllerModal] = useState(false);
  //
  // const videoRef = useRef<HTMLVideoElement>(null);
  //
  // const attachTracks = useCallback(
  //   (track: RemoteTrack) => {
  //     if (videoRef.current) {
  //       const ref = videoRef.current;
  //
  //       track.attach(ref);
  //
  //       ref.muted = true;
  //       ref.volume = 0;
  //     }
  //   },
  //   [videoRef]
  // );
  //
  // useRoomListeners({
  //   [RoomEvent.TrackSubscribed]: attachTracks,
  // });

  const handleOpenDialog = () => {
    setOpenControllerModal(true);
  };

  const handleResume = () => {};

  return (
    <>
      <div className="flex overflow-hidden justify-center relative w-full border-solid">
        Эфир запущен
        {/*<video className="w-full" ref={videoRef} muted />*/}
      </div>
      <div className="flex flex-row gap-3 pt-6 w-full justify-center">
        <Button
          onClick={handleOpenDialog}
          title="Завершить"
          className="w-full max-w-[350px]"
        />
        {isOpenControllerModal && (
          <ControllerRecordModal
            type="create"
            onSave={onSave}
            onResume={handleResume}
          />
        )}
      </div>
      <Presence />
    </>
  );
};

import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { clansSelector } from 'store';
import { getClansThunk } from 'store/slices/clan/actions';

import { Navigation } from 'components/Navigation';

interface IClan {
  index: number;
  name: string;
}

interface HeaderNavigationProps {
  isAllClans?: boolean;
}

export const HeaderNavigation: FC<HeaderNavigationProps> = ({ isAllClans }) => {
  const [selectedClan, setSelectedClan] = useState<IClan>();

  const { clans } = useAppSelector(clansSelector);

  const dispatch = useAppDispatch();

  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getClansThunk());
  }, []);

  useEffect(() => {
    if (clans) {
      if (id) {
        const clanIndex = clans.findIndex((clan) => clan.id === id);

        if (clanIndex !== -1) {
          const currentClan = {
            index: clanIndex,
            name: clans[clanIndex].name,
          };

          setSelectedClan(currentClan);
        }
      }

      if (isAllClans) {
        const currentClan = {
          index: 0,
          name: clans[0].name,
        };

        setSelectedClan(currentClan);
      }
    }
  }, [clans]);

  const handleClickArrow = (value: number) => {
    if (clans) {
      const maybeSelected = clans[value];

      if (maybeSelected) {
        navigate(`/clan/${maybeSelected.id}`);

        setSelectedClan({
          index: value,
          name: maybeSelected.name,
        });
      }
    }
  };

  if (clans.length <= 1) return null;

  return (
    <>
      {selectedClan?.name && (
        <Navigation
          text={selectedClan.name}
          onClickRight={() => handleClickArrow(selectedClan.index + 1)}
          onClickLeft={() => handleClickArrow(selectedClan.index - 1)}
          isDisableRightButton={selectedClan.index + 1 === clans.length}
          isDisableLeftButton={selectedClan.index === 0}
        />
      )}
    </>
  );
};

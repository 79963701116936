import { FC, useEffect, useRef, useState } from 'react';
import { getComputers } from 'api/computer';
import { errorMessages } from 'constants/messages';
import { EUserRole } from 'constants/profile';
import { useAppDispatch } from 'hooks';
import { ReactComponent as PlusIcon } from 'images/newIcons/plus.svg';
import { IOption } from 'interfaces';
import { IClan } from 'interfaces/clan';
import { IPlayer, IUpdatedPlayerData } from 'interfaces/player';
import {
  deleteClanMemberThunk,
  getClanMembersThunk,
} from 'store/slices/clan/actions';
import { registerWithClanThunk } from 'store/slices/player/actions';
import { ID } from 'types/common';

import { getWordDayForm, notify } from 'utils';

import { AddPlayerCard } from './components/AddPlayerCard';
import { InfoTable } from './components/InfoTable';

interface PlayerInfoProps {
  role: EUserRole;
  selectedClan: IClan;
  newPlayers: IPlayer[];
  onAddPlayer: (value: IPlayer) => void;
  onRemovePlayer: (id: ID) => void;
  onUpdatePlayer: (value: IUpdatedPlayerData) => void;
  isNewClan?: boolean;
}

export const PlayerInfo: FC<PlayerInfoProps> = ({
  role,
  selectedClan,
  newPlayers,
  onAddPlayer,
  onRemovePlayer,
  onUpdatePlayer,
  isNewClan,
}) => {
  const [computerOptions, setComputerOptions] = useState<IOption[]>([]);

  const { players: playersSelectedClan } = selectedClan;

  const players = isNewClan ? newPlayers : playersSelectedClan;

  const dispatch = useAppDispatch();

  const [isNewPlayer, setNewPlayer] = useState(false);

  useEffect(() => {
    if (!isNewClan) {
      dispatch(getClanMembersThunk(selectedClan.id));
    }
  }, []);

  useEffect(() => {
    const getComputersData = async () => {
      try {
        const { data } = await getComputers();

        const options = data.map(({ id, name }) => ({
          value: String(id),
          label: name,
        }));

        setComputerOptions(options);
      } catch (error) {
        notify.warning(errorMessages.GET_COMPUTERS);
      }
    };

    getComputersData();
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleAddPlayer = () => {
    setNewPlayer(true);

    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleAddNewPlayer = (player: IPlayer) => {
    if (!isNewClan && selectedClan) {
      dispatch(
        registerWithClanThunk({
          email: player.email,
          name: player.name,
          password: player.password,
          password2: player.password,
          clanId: selectedClan.id,
          roleId: player.role,
          isAddedFromClan: true,
        })
      );
    }

    onAddPlayer(player);
  };

  const handleRemovePlayer = (id: ID) => {
    if (!isNewClan) {
      dispatch(
        deleteClanMemberThunk({
          clanId: selectedClan.id,
          memberId: id,
        })
      );
    }

    onRemovePlayer(id);
  };

  const handleClose = () => {
    setNewPlayer(false);
  };

  const wordForm = getWordDayForm(players.length, [
    'пользователь',
    'пользователя',
    'пользователей',
  ]);

  return (
    <div ref={containerRef} className="w-6/12 min-h-full overflow-auto">
      <div className="flex flex-row justify-between bg-ultrablack px-[16px] pt-[16px] pb-[8px] sticky top-0 z-[2]">
        <span>{`${players.length} ${wordForm}`}</span>
        <div
          className="group cursor-pointer flex items-center justify-center transition"
          onClick={handleAddPlayer}
        >
          {(role === EUserRole.ADMIN || role === EUserRole.ROOT) && (
            <PlusIcon className="scale-125 group-hover:[&>g>path]:fill-bright_product [&>g>path]:transition" />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-[16px] px-[16px] pt-[8px] pb-[16px]">
        {isNewPlayer && (
          <AddPlayerCard
            setNewPlayer={handleAddNewPlayer}
            onClose={handleClose}
          />
        )}
        {!!players.length &&
          players.map((info) => (
            <InfoTable
              key={info.id}
              globalRole={role}
              onRemove={handleRemovePlayer}
              onUpdatePlayer={onUpdatePlayer}
              isNewClan={isNewClan}
              clanId={selectedClan.id}
              computerOptions={computerOptions}
              launchedStreams={2} // temporary mock
              {...info}
            />
          ))}
      </div>
    </div>
  );
};

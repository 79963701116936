import { FC } from 'react';

import { Switch } from 'components/ui';
import { SwitchProps } from 'components/ui/Switch';

interface SwitchWithLabelProps extends SwitchProps {
  label: string;
}

export const SwitchWithLabel: FC<SwitchWithLabelProps> = ({
  label,
  checked,
  disabled,
  onChange,
  size = 's',
}) => (
  <div className="flex flex-row gap-[4px] items-center">
    <span className="tpg-c2">{label}</span>
    <Switch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      size={size}
    />
  </div>
);

import { FC, useEffect, useState } from 'react';
import { SEPARATOR } from 'constants/livekit';
import { LP_INTERVAL_MS } from 'constants/profile';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IRoomParams } from 'interfaces';
import { IClan } from 'interfaces/clan';
import { clansSelector } from 'store/slices/clan';
import { roomSelector } from 'store/slices/room';
import {
  getClanRoomsThunk,
  getLiveKitTokenThunk,
} from 'store/slices/room/actions';

import { Panel } from './Panel';

interface AllClansStreamsPanelProps {
  isOpenFullScreen: boolean;
  onBack: () => void;
}

export const AllClansStreamsPanel: FC<AllClansStreamsPanelProps> = ({
  isOpenFullScreen,
  onBack,
}) => {
  const [filteredRooms, setFilteredRooms] = useState<IRoomParams[]>([]);

  const { rooms } = useAppSelector(roomSelector);

  const { clans } = useAppSelector(clansSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const getRooms = () => {
      if (clans) {
        clans.forEach(({ id }) => {
          dispatch(getClanRoomsThunk(id));
        });
      }
    };

    getRooms();

    const roomsInterval = setInterval(getRooms, LP_INTERVAL_MS);

    return () => {
      clearInterval(roomsInterval);
    };
  }, [clans]);

  useEffect(() => {
    const treeRooms = clans.reduce(
      (acc: IRoomParams[], clan: IClan): IRoomParams[] => {
        const activeRooms = rooms.get(clan.id)?.active;

        if (activeRooms?.length) {
          const convertedRooms = activeRooms.map((room) => ({
            ...room,
            clanName: clan.name,
            clanId: String(clan.id),
          }));

          acc = [...acc, ...convertedRooms];
        }

        return acc;
      },
      []
    );

    setFilteredRooms(treeRooms);
  }, [clans, rooms]);

  useEffect(() => {
    if (filteredRooms?.length) {
      filteredRooms.forEach((room) => {
        const [clanId, roomName] = room.livekitName.split(SEPARATOR);

        if (!room.token) {
          dispatch(getLiveKitTokenThunk({ clanId, roomName }));
        }
      });
    }
  }, [filteredRooms]);

  return (
    <Panel
      isOpenFullScreen={isOpenFullScreen}
      onBack={onBack}
      rooms={filteredRooms}
    />
  );
};

import { FC, ReactNode, useRef, useState } from 'react';
import cn from 'classnames';
import { useClickOutside } from 'hooks';
import { ReactComponent as Menu } from 'images/newIcons/menu.svg';

interface ControlPanelMenuProps {
  content: ReactNode;
}

export const ControlPanelMenu: FC<ControlPanelMenuProps> = ({ content }) => {
  const [isOpen, setOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside<HTMLDivElement, void>(containerRef, () => setOpen(false));

  const handleClickMenu = () => {
    setOpen(!isOpen);
  };

  return (
    <div ref={containerRef} className="relative">
      <div
        className="h-[20px] flex flex-row group cursor-pointer justify-center items-center"
        onClick={handleClickMenu}
      >
        <Menu
          className={cn(
            'w-[20px] h-[20px] [&>path]:fill-bright_product group-hover:[&>path]:fill-bright_product [&>path]:transition',
            {
              '[&>path]:fill-tpg_title': !isOpen,
            }
          )}
        />
      </div>
      {isOpen && (
        <div className="flex flex-col items-start gap-[8px] justify-start absolute bg-ultrablack border border-solid rounded-[10px] border-tpg_light top-[25px] left-0 min-w-full p-[12px]">
          {content}
        </div>
      )}
    </div>
  );
};

import { FC, useState } from 'react';
import { EUserRole } from 'constants/profile';
import { useAppSelector } from 'hooks';
import { ReactComponent as PlusIcon } from 'images/newIcons/plus.svg';
import { authSelector } from 'store';

import { TextInput } from 'components/ui';
import { isMobileDevice } from 'utils';

import { mapboxDraw } from './MapComponent/DrawControl';

interface ControlPanelProps {
  onChange: (value: string) => void;
}

export const ControlPanel: FC<ControlPanelProps> = ({ onChange }) => {
  const [searchValue, setSearchValue] = useState('');

  const { role } = useAppSelector(authSelector);

  const handleChangeSearch = (value: string) => {
    setSearchValue(value);
    onChange(value);
  };

  const handleClickButton = () => {
    mapboxDraw.changeMode('draw_polygon');
  };

  const isMobile = isMobileDevice();

  return (
    <div className="w-full h-[48px] flex flex-row items-center justify-between bg-dark sticky top-0 z-10 pr-[24px]">
      <TextInput
        value={searchValue}
        placeholder="Начните поиск"
        searchIcon
        isReset
        className="w-full bg-dark max-w-[540px] xl:max-w-[300px]"
        theme="dark"
        onChange={handleChangeSearch}
      />
      {(EUserRole.ROOT === role || EUserRole.ADMIN === role) && !isMobile && (
        <div
          className="h-[20px] gap-[4px] flex flex-row group cursor-pointer justify-center min-w-[140px] hover:text-bright_product transition"
          onClick={handleClickButton}
        >
          <span className="tpg-c2">Добавить подразделение</span>
          <PlusIcon className="group-hover:[&>g>path]:fill-bright_product w-[20px] h-[20px] [&>g>path]:transition" />
        </div>
      )}
    </div>
  );
};

import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalParticipant } from '@livekit/components-react';
import { successMessages, warningMessages } from 'constants/messages';
import { useRoomListeners } from 'hooks';
import { useTrackpad } from 'hooks';
import { TSaveRecordParams } from 'interfaces/record';
import { RoomEvent, Track } from 'livekit-client';
import { TStreamStatus } from 'types';

import { notify } from 'utils';

import { HostControlPanel } from '../HostControlPanel';
import { StreamDuration } from '../StreamDuration';
import StreamStatus from '../StreamStatus';

import { useScreenTracks } from './useScreenTracks';

interface IBrowserHostStream {
  isRecording?: boolean;
  onDeleteRoom: (params?: TSaveRecordParams) => void;
  onPublish: () => void;
}

export const BrowserHostStream: FC<IBrowserHostStream> = ({
  isRecording,
  onDeleteRoom,
  onPublish,
}) => {
  const [isPlay, setPlay] = useState(false);
  const navigate = useNavigate();
  const { localParticipant } = useLocalParticipant();

  const { createScreenTracks, stopStream, videoTrack, audioTrack, videoEl } =
    useScreenTracks(localParticipant, {
      onError: (error) => {
        console.log(error);
        notify.warning(warningMessages.DESELECT_STREAM);
        navigate(-1);
      },
      onSuccess: () => {
        setPlay(true);
      },
      screenTrackOptions: {
        audio: {
          autoGainControl: true,
          channelCount: 2,
          echoCancellation: false,
          noiseSuppression: false,
        },
        video: {
          displaySurface: 'browser',
        },
        surfaceSwitching: 'exclude',
      },
    });

  const { pauseTracks, resumeTracks } = useTrackpad([videoTrack, audioTrack]);

  useRoomListeners({
    [RoomEvent.Connected]: createScreenTracks,
    [RoomEvent.LocalTrackPublished]: (pub) => {
      if (pub.kind === Track.Kind.Video) {
        notify.success(successMessages.ADD_STREAM);
        onPublish();
      }
    },
    [RoomEvent.LocalTrackUnpublished]: (pub) => {
      if (pub.kind === Track.Kind.Video) {
        notify.warning(warningMessages.INTERRUPT_STREAM);
      }
    },
  });

  const handlePauseStream = () => {
    pauseTracks();
    setPlay(false);
  };

  const handleResumeStream = () => {
    resumeTracks();
    setPlay(true);
  };

  const handleStopStream = (params?: TSaveRecordParams) => {
    stopStream();
    onDeleteRoom(params);
  };

  const status = useMemo<TStreamStatus>(() => {
    if (isPlay && localParticipant.trackPublications.size) return 'online';

    if (!isPlay && localParticipant.trackPublications.size) return 'pause';

    return 'offline';
  }, [isPlay, localParticipant.trackPublications.size]);

  return (
    <>
      <div className="flex flex-row justify-between">
        <StreamStatus status={status} />
        <StreamDuration
          isCounting={status === 'online'}
          isStopped={status === 'offline'}
        />
      </div>

      <div className="flex overflow-hidden aspect-video justify-center relative w-full border-solid border-tpg_light border-[1px] rounded-[10px]">
        <video className="w-full" ref={videoEl} muted />
      </div>
      <div>
        <HostControlPanel
          isRecording={isRecording}
          status={status}
          isPlay={isPlay}
          onStart={createScreenTracks}
          onPause={handlePauseStream}
          onResume={handleResumeStream}
          onStop={handleStopStream}
        />
      </div>
    </>
  );
};

import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { centroid } from '@turf/turf';
import { getAccount } from 'api/account';
import { ELStorageKeys } from 'constants/localStorage';
import {
  errorMessages,
  successMessages,
  warningMessages,
} from 'constants/messages';
import { EUserRole, roleWeights } from 'constants/profile';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IOption } from 'interfaces';
import { IClan } from 'interfaces/clan';
import { IPlayer, IUpdatedPlayerData } from 'interfaces/player';
import { mockHistory, NEW_ID } from 'mock';
import { authSelector, clanActions, clansSelector } from 'store';
import {
  createClanThunk,
  deleteClanThunk,
  getClanThunk,
  updateClanThunk,
} from 'store/slices/clan/actions';
import { ID } from 'types';

import { AdminTable } from 'components/AdminTable';
import { DeleteModal } from 'components/DeleteModal';
import { Loader } from 'components/ui/Loader';
import { notify } from 'utils';
import { getLStorage } from 'utils/helpers/localStorage';

import { HistoryInfo } from '../../components/HistoryInfo';

import { BasicInfo } from './BasicInfo';
import { PlayerInfo } from './PlayerInfo';

interface ClanAdminPanelScreenProps {
  isNewClan?: boolean;
}

export type TClanInfo = Omit<IClan, 'computer' | 'access'> & {
  computer: IOption;
  access: IOption[];
};

export const newClanInitialState = {
  id: NEW_ID,
  name: '',
  computers: [],
  users_count: 0,
  recordings_count: 0,
  work_area: '',
  access: [],
  players: [],
  longitude: 0,
  latitude: 0,
  coordinates: [],
};

export const ClanAdminPanelScreen: FC<ClanAdminPanelScreenProps> = ({
  isNewClan,
}) => {
  const [newPlayers, setNewPlayers] = useState<IPlayer[]>([]);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const { role, roles } = useAppSelector(authSelector);

  const { selectedClan, pending } = useAppSelector(clansSelector);

  const dispatch = useAppDispatch();

  const { id: clanId } = useParams();

  const { state } = useLocation();

  const navigate = useNavigate();

  const accountId = getLStorage(ELStorageKeys.accountId);

  const priorityRole = useMemo(() => {
    const roleSelectedClan = roles.find(
      (role) => role.clan_id === selectedClan?.id
    )?.role_name;

    if (role === EUserRole.ROOT) {
      return EUserRole.ROOT;
    }

    if (
      role &&
      roleSelectedClan &&
      roleWeights[roleSelectedClan] < roleWeights.moderator &&
      roleWeights[role] >= roleWeights.moderator
    ) {
      return EUserRole.MODERATOR;
    }

    return roleSelectedClan || role;
  }, [roles, selectedClan]);

  useEffect(() => {
    if (isNewClan) {
      dispatch(clanActions.setNewClan(newClanInitialState));

      return;
    }

    if (clanId && clanId !== NEW_ID) {
      dispatch(getClanThunk(clanId));

      return;
    }

    const getAccountById = async () => {
      if (accountId) {
        try {
          const { roles } = await getAccount(accountId);

          if (role === EUserRole.MODERATOR) {
            const moderatorClanId = roles.find(
              (role) => role.role_name === EUserRole.MODERATOR
            )?.clan_id;

            moderatorClanId && dispatch(getClanThunk(moderatorClanId));
          }
        } catch (error) {
          notify.error(errorMessages.GET_ACCOUNT);
        }
      }
    };

    getAccountById();
  }, [clanId, isNewClan, role]);

  useEffect(() => {
    if (state?.features) {
      const polygonСenter =
        state?.features && centroid(state.features).geometry.coordinates;

      dispatch(
        clanActions.modifySelected({
          coordinates: state?.features.geometry.coordinates,
          longitude: polygonСenter[0],
          latitude: polygonСenter[1],
        })
      );
    }
  }, [state]);

  const handleAddNewPlayer = (value: IPlayer) => {
    setNewPlayers((prev) => [...prev, value]);
  };

  const handleRemovePlayer = (id: ID) => {
    setNewPlayers((prev) => prev.filter((player) => player.id !== id));
  };

  const handleUpdatePlayer = (value: IUpdatedPlayerData) => {
    setNewPlayers((prev) =>
      prev.map((player) =>
        player.id === value.id ? { ...player, ...value } : player
      )
    );
  };

  const handleChangeClanInfo = (value: Partial<IClan>) => {
    dispatch(clanActions.modifySelected(value));
  };

  const handleUpdateClan = () => {
    if (selectedClan && selectedClan.name) {
      isNewClan
        ? dispatch(
            createClanThunk({
              clan: { ...selectedClan, admin_id: String(accountId) },
              players: newPlayers,
            })
          )
        : dispatch(updateClanThunk(selectedClan));

      notify.success(successMessages.SAVE_DATA);

      navigate(-1);
    } else {
      notify.warning(warningMessages.ENTER_CLAN_NAME);
    }
  };

  const handleRemoveClan = () => {
    if (clanId) {
      dispatch(deleteClanThunk(clanId));

      notify.success(successMessages.DELETE_CLAN);

      navigate(-1);
    }
  };

  const handleCloseModal = () => {
    setOpenDeleteModal(false);
  };

  const handleOpenModal = () => {
    setOpenDeleteModal(true);
  };

  if (!selectedClan || !priorityRole) {
    return <div>Не удалось загрузить данные</div>;
  }

  if (pending)
    return (
      <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center">
        <Loader />
      </div>
    );

  return (
    <>
      <AdminTable
        title={!isNewClan ? selectedClan.name : 'Новое подразделение'}
        historyTitle="История эфиров"
        listTitle="Список пользователей"
        isShowFooterController={
          priorityRole === EUserRole.ADMIN || priorityRole === EUserRole.ROOT
        }
        isNewData={isNewClan}
        info={
          <BasicInfo
            role={priorityRole}
            clanInfo={selectedClan}
            isNewClan={isNewClan}
            onChange={handleChangeClanInfo}
          />
        }
        history={<HistoryInfo history={mockHistory} />}
        list={
          <PlayerInfo
            role={priorityRole}
            selectedClan={selectedClan}
            isNewClan={isNewClan}
            newPlayers={newPlayers}
            onAddPlayer={handleAddNewPlayer}
            onRemovePlayer={handleRemovePlayer}
            onUpdatePlayer={handleUpdatePlayer}
          />
        }
        onSave={handleUpdateClan}
        onRemove={handleOpenModal}
      />
      {isOpenDeleteModal && (
        <DeleteModal
          title="Вы уверены, что хотите удалить подразделение?"
          onClose={handleCloseModal}
          onRemove={handleRemoveClan}
        />
      )}
    </>
  );
};

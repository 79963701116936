import { FC, useEffect } from 'react';
import cn from 'classnames';
import { LP_INTERVAL_MS } from 'constants/profile';
import { useAppDispatch, useAppSelector, useBreakPoint } from 'hooks';
import { roomSelector } from 'store/slices/room';
import { getClanRoomsThunk } from 'store/slices/room/actions';

import { Card } from 'components/Card';

interface IOnlineSectionProps {
  clanId: string;
}

export const OnlineSection: FC<IOnlineSectionProps> = ({ clanId }) => {
  const { rooms } = useAppSelector(roomSelector);

  const dispatch = useAppDispatch();
  const breakPoint = useBreakPoint();

  const clanRooms = rooms.get(clanId)?.active || [];

  useEffect(() => {
    const getRooms = () => {
      if (clanId) {
        dispatch(getClanRoomsThunk(clanId));
      }
    };

    getRooms();

    const roomsInterval = setInterval(getRooms, LP_INTERVAL_MS);

    return () => {
      clearInterval(roomsInterval);
    };
  }, [clanId]);

  return (
    <div className="flex flex-col gap-[16px]">
      <span className="tpg-h4">{`${clanRooms.length} в эфире`}</span>
      <div
        className={cn('grid grid-cols-2 gap-[16px] w-full', {
          'grid-cols-1': breakPoint === 'mobile',
        })}
      >
        {clanRooms.map(({ tags, livekitName, src, publisherName }) => (
          <Card
            key={livekitName}
            cardId={String(livekitName)}
            src={src}
            tags={tags}
            type="stream"
            clanId={clanId}
            publisherName={publisherName}
          />
        ))}
      </div>
    </div>
  );
};

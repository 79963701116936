import { useEffect, useRef, useState } from 'react';
import { IVideoPlayer } from 'types/video-player';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';

const options = {
  fill: true,
  fluid: true,
  autoplay: false,
  controls: true,
};

export const usePlayer = ({ src, poster }: IVideoPlayer) => {
  const videoRef = useRef(null);
  const [player, setPlayer] = useState<Player>();

  useEffect(() => {
    if (videoRef.current) {
      const vjsPlayer = videojs(videoRef.current, {
        ...options,
        sources: [src],
        poster,
      });

      setPlayer(vjsPlayer);
    }

    return () => {
      if (player !== null) {
        player?.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (player !== null) {
      player?.src({ src });
      player?.pause();
    }
  }, [src, player]);

  return videoRef;
};

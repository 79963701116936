import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { IOption } from 'interfaces';

import { CopyIcon } from 'components/CopyIcon';
import { GeneratePassword } from 'components/GeneratePassword';
import { Select, TextInput } from 'components/ui';
import { maskPassword } from 'utils';

import { TPlayerInfo } from '.';

interface BasicInfoProps {
  playerInfo: TPlayerInfo;
  computerOptions: IOption[];
  onChange: (info: TPlayerInfo) => void;
  isAccessEditing: boolean;
  isNewPlayer?: boolean;
}

const mockTableHead = [
  {
    id: 'name',
    label: 'Позывной',
  },
  // {
  //   id: 'launchedStreams',
  //   label: 'Записанных эфиров',
  // },
  {
    id: 'login',
    label: 'Логин',
  },
  {
    id: 'password',
    label: 'Пароль',
  },
];

export const BasicInfo: FC<BasicInfoProps> = ({
  playerInfo,
  computerOptions,
  onChange,
  isAccessEditing,
  isNewPlayer,
}) => {
  const [selectedComputer, setSelectedComputer] = useState<IOption>({
    value: '',
    label: '',
  });

  const tableHeader = isNewPlayer
    ? mockTableHead
    : [
        ...mockTableHead,
        {
          id: 'computer',
          label: 'Техника',
        },
      ];

  useEffect(() => {
    const computerInitialValue = computerOptions.find(
      (option) => option.value === playerInfo.computer?.id
    );

    if (computerInitialValue) {
      setSelectedComputer(computerInitialValue);
    }
  }, [playerInfo, computerOptions]);

  const handleChangeName = (value: string) => {
    onChange({ ...playerInfo, name: value });
  };

  const handleSelectComputer = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setSelectedComputer(value);

      onChange({
        ...playerInfo,
        computer: { id: value.value, name: value.label },
      });
    }
  };

  const handleChangeLogin = (value: string) => {
    onChange({ ...playerInfo, email: value });
  };

  const handleChangePassword = (value: string) => {
    onChange({ ...playerInfo, password: value });
  };

  const passwordInitialValue =
    isNewPlayer && !playerInfo.password ? '' : 'password';

  return (
    <div className="w-6/12 border-r border-solid border-r-tpg_light overflow-auto min-h-full">
      <div className="bg-ultrablack px-[16px] pt-[16px] pb-[8px] sticky top-0 z-[3]">
        <span>Основная информация</span>
      </div>
      <div className="px-[16px] pt-[8px] pb-[16px] [&>div]last:border-b-none">
        <div className="flex flex-row border border-solid border-dark_product rounded-[10px]">
          <div className="flex flex-col w-6/12 border-r border-solid border-r-dark_product min-w-[195px]">
            {tableHeader.map((head) => (
              <div
                key={head.id}
                className="p-[12px] border-b border-solid border-b-dark_product h-[44px] last:!border-none"
              >
                <span className="tpg-c1">{head.label}</span>
              </div>
            ))}
          </div>
          <div className="flex flex-col w-6/12">
            <div className="p-[12px] border-b border-solid border-b-dark_product h-[44px] text-tpg_base tpg-c1">
              {(isAccessEditing || isNewPlayer) && !playerInfo.banned ? (
                <TextInput
                  onChange={handleChangeName}
                  placeholder="Введите название"
                  value={playerInfo.name}
                  inputClassName="bg-ultrablack !h-[19px] !pl-0 !text-tpg_base"
                />
              ) : (
                <span>{playerInfo.name}</span>
              )}
            </div>
            {/* TODO: return when launched streams data is ready */}
            {/* <div className="p-[12px] border-b border-solid border-b-dark_product h-[44px] text-tpg_base tpg-c1">
              <span>{playerInfo.launchedStreams ?? 0}</span>
            </div> */}
            <div className="p-[12px] border-b border-solid border-b-dark_product h-[44px] text-tpg_base tpg-c1">
              {(isAccessEditing || isNewPlayer) && !playerInfo.banned ? (
                <div className="flex flex-row items-center gap-[8px]">
                  <TextInput
                    value={playerInfo.email}
                    inputClassName="bg-ultrablack h-[19px] !pl-0 !text-tpg_base"
                    className="w-full"
                    placeholder="Введите значение"
                    onChange={handleChangeLogin}
                  />
                  <CopyIcon value={playerInfo.email} />
                </div>
              ) : (
                <span>{playerInfo.email}</span>
              )}
            </div>
            <div
              className={cn('p-[12px] h-[44px] text-tpg_base tpg-c1', {
                'border-b border-solid border-b-dark_product': !isNewPlayer,
              })}
            >
              <div className="flex flex-row justify-between items-center">
                <span className="tpg-c1 pointer-events-none">
                  {maskPassword(playerInfo.password || passwordInitialValue)}
                </span>
                {(isAccessEditing || isNewPlayer) && !playerInfo.banned && (
                  <div className="flex flex-row gap-[8px]">
                    <GeneratePassword
                      onClick={handleChangePassword}
                      label="Сгенерировать"
                    />
                    {playerInfo.password && (
                      <CopyIcon value={playerInfo.password} />
                    )}
                  </div>
                )}
              </div>
            </div>
            {!isNewPlayer && (
              <div className="p-[12px] h-[44px] text-tpg_base tpg-c1">
                {isAccessEditing && !playerInfo.banned ? (
                  <Select
                    value={selectedComputer}
                    options={computerOptions}
                    onSelect={handleSelectComputer}
                    className="!min-h-[20px] !pr-0"
                  />
                ) : (
                  <span>{selectedComputer.label || 'Не выбрано'}</span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

import { FC, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { warningMessages } from 'constants/messages';
import { useRoomListeners } from 'hooks';
import { RemoteTrack, RoomEvent, Track } from 'livekit-client';

import { notify } from 'utils';

import { ControlPanel } from '../ControlPanel';
import { defaultVolume } from '../ControlPanel/constants';

interface IViewerPlayerProps {
  isMuted?: boolean;
  publisherName?: string;
}

export const ViewerPlayer: FC<IViewerPlayerProps> = ({
  isMuted,
  publisherName,
}) => {
  const navigate = useNavigate();

  const videoEl = useRef<HTMLVideoElement>(null);
  const playerEl = useRef<HTMLDivElement>(null);

  const [isPause, setPause] = useState(false);
  const [tracks, setTracks] = useState<RemoteTrack[]>([]);

  const attachTracks = useCallback(
    (track: RemoteTrack) => {
      setTracks((prev) => [...prev, track]);

      if (videoEl.current) {
        const ref = videoEl.current;

        track.attach(ref);

        if (isMuted) {
          ref.muted = true;
          ref.volume = 0;
        }
      }
    },
    [videoEl]
  );

  useRoomListeners({
    [RoomEvent.TrackSubscribed]: attachTracks,
    [RoomEvent.TrackUnpublished]: (pub) => {
      if (pub.kind === Track.Kind.Video) {
        if (publisherName) {
          notify.warning(`${publisherName} завершил эфир`);

          return;
        }

        notify.warning(warningMessages.FINISH_STREAM);

        navigate(-1);
      }
    },
    [RoomEvent.TrackMuted]: (pub) => {
      if (pub.kind === Track.Kind.Video && pub.isMuted) {
        if (publisherName) {
          notify.warning(`${publisherName} поставил эфир на паузу`);

          return;
        }

        notify.warning(warningMessages.PAUSE_STREAM);
      }
    },
    [RoomEvent.TrackUnmuted]: (pub) => {
      if (pub.kind === Track.Kind.Video && !pub.isMuted) {
        if (publisherName) {
          notify.warning(`${publisherName} возобновил эфир`);

          return;
        }

        notify.warning(warningMessages.RESUME_STREAM);
      }
    },
  });

  const handleClickPause = (flag: boolean) => {
    setPause(flag);
  };

  return (
    <div
      className="flex overflow-hidden aspect-video justify-center relative w-full border-solid border-tpg_light border-[1px] rounded-[10px]"
      ref={playerEl}
    >
      <video className="w-full" ref={videoEl} />
      <ControlPanel
        playerEl={playerEl.current}
        videoEl={videoEl.current}
        tracks={tracks}
        isPause={isPause}
        initialVolume={isMuted ? 0 : defaultVolume}
        onPause={handleClickPause}
      />
    </div>
  );
};

import { EUserRole } from './profile';
import { appRoutes } from './routes';

export const navs = {
  [EUserRole.MODERATOR]: [
    {
      link: appRoutes.CLANS,
      title: 'Эфиры',
    },
    {
      link: appRoutes.MAP,
      title: 'Карта',
    },
    {
      link: appRoutes.ADMIN,
      title: 'Администрирование',
    },
  ],
  [EUserRole.USER]: [],
  [EUserRole.ADMIN]: [
    {
      link: appRoutes.CLANS,
      title: 'Эфиры',
    },
    {
      link: appRoutes.MAP,
      title: 'Карта',
    },
    {
      link: appRoutes.ADMIN,
      title: 'Администрирование',
    },
  ],
  [EUserRole.ROOT]: [
    {
      link: appRoutes.CLANS,
      title: 'Эфиры',
    },
    {
      link: appRoutes.MAP,
      title: 'Карта',
    },
    {
      link: appRoutes.ADMIN,
      title: 'Администрирование',
    },
  ],
};

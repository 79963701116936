import { createSlice } from '@reduxjs/toolkit';
import { ELStorageKeys } from 'constants/localStorage';
import { errorMessages, successMessages } from 'constants/messages';
import { reducersNames } from 'constants/reducers';
import { IAccountState, TSliceWithPromiseFlags } from 'interfaces';

import { notify } from 'utils';
import { getLStorage } from 'utils/helpers/localStorage';

import {
  getAccountThunk,
  updateAccountEmailThunk,
  updateAccountPasswordThunk,
  updateAccountRoleThunk,
  updateAccountThunk,
} from './actions';

const initialState: TSliceWithPromiseFlags<IAccountState> = {
  pending: false,
  error: null,
  email: '',
  external_id: '',
  id: getLStorage(ELStorageKeys.accountId) || '',
  name: '',
  roles: [],
};

const accountSlice = createSlice({
  name: reducersNames.AUTH,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAccountThunk.fulfilled, (state, { payload }) => {
        state.pending = false;
        state.error = null;
        state.roles = payload.roles;
        state.id = String(payload.id);
        state.name = payload.name;
      })
      .addCase(getAccountThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getAccountThunk.rejected, (state) => {
        state.pending = false;
        state.error = errorMessages.LOADING_ERROR;
      })
      .addCase(updateAccountThunk.fulfilled, (state) => {
        state.pending = false;
        state.error = null;

        notify.success(successMessages.UPDATE_PLAYER);
      })
      .addCase(updateAccountThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(updateAccountThunk.rejected, (state) => {
        state.pending = false;
        state.error = errorMessages.LOADING_ERROR;
      })
      .addCase(updateAccountPasswordThunk.fulfilled, (state) => {
        state.pending = false;
        state.error = null;

        notify.success(successMessages.UPDATE_PASSWORD);
      })
      .addCase(updateAccountPasswordThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(updateAccountPasswordThunk.rejected, (state) => {
        state.pending = false;
        state.error = errorMessages.LOADING_ERROR;
      })
      .addCase(updateAccountEmailThunk.fulfilled, (state) => {
        state.pending = false;
        state.error = null;

        notify.success(successMessages.UPDATE_LOGIN);
      })
      .addCase(updateAccountEmailThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(updateAccountEmailThunk.rejected, (state) => {
        state.pending = false;
        state.error = errorMessages.LOADING_ERROR;
      })
      .addCase(updateAccountRoleThunk.fulfilled, (state) => {
        state.pending = false;
        state.error = null;

        notify.success(successMessages.UPDATE_ROLE);
      })
      .addCase(updateAccountRoleThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(updateAccountRoleThunk.rejected, (state) => {
        state.pending = false;
        state.error = errorMessages.LOADING_ERROR;
      });
  },
});

export const { actions: accountActions, reducer: accountReducer } =
  accountSlice;

export * from './selectors';

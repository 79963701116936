import { useLocation } from 'react-router-dom';

import { RecordSection } from 'components/RecordSection';

export const RecordsScreen = () => {
  const {
    state: { clanId },
  } = useLocation();

  return (
    <div className="flex flex-col gap-[16px]">
      {clanId ? (
        <RecordSection clanId={clanId} />
      ) : (
        <span>Нет данных о подразделении</span>
      )}
    </div>
  );
};

import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { navigation } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'hooks';
import { authSelector } from 'store';
import { getAccountThunk } from 'store/slices/account/actions';

import { StreamLaunch } from './StreamLaunch';

export const StreamLaunchScreen = () => {
  const { username, id: accountId, role } = useAppSelector(authSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (accountId && !username) {
      dispatch(getAccountThunk(accountId));
    }
  }, []);

  if (!username || !accountId)
    return <Navigate to={role ? navigation[role] : '/'} replace />;

  return <StreamLaunch username={username} />;
};

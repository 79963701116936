import { FC, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { LP_INTERVAL_MS } from 'constants/profile';
import { useAppDispatch, useAppSelector, useBreakPoint } from 'hooks';
import { IRecord } from 'interfaces/record';
import { IRoom } from 'interfaces/room';
import { recordsSelector } from 'store/slices/records';
import { getClanRecordsThunk } from 'store/slices/records/actions';
import { roomSelector } from 'store/slices/room';
import { getClanRoomsThunk } from 'store/slices/room/actions';

import { Card } from 'components/Card';
import { isMobileDevice } from 'utils';

interface IClansStreamListProps {
  id: string;
  clan: string;
  place: string;
}

export const ClansStreamList: FC<IClansStreamListProps> = ({
  id,
  clan,
  place,
}) => {
  const { rooms, pending: roomsPending } = useAppSelector(roomSelector);
  const { records, pending: recordsPending } = useAppSelector(recordsSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const breakPoint = useBreakPoint();

  const clanRecords = records.get(id) || [];
  const clanRooms = rooms.get(id)?.active || [];

  const handleNavigateClan = () => {
    navigate(`/clan/${id}`);
  };

  useEffect(() => {
    dispatch(getClanRoomsThunk(id));
    dispatch(getClanRecordsThunk({ clanId: id }));

    const roomsInterval = setInterval(() => {
      if (!roomsPending) {
        dispatch(getClanRoomsThunk(id));
      }
    }, LP_INTERVAL_MS);

    const recordsInterval = setInterval(() => {
      if (!recordsPending) {
        dispatch(getClanRecordsThunk({ clanId: id }));
      }
    }, LP_INTERVAL_MS);

    return () => {
      clearInterval(roomsInterval);
      clearInterval(recordsInterval);
    };
  }, []);

  const roomList: IRoom[] = useMemo(
    () => (clanRooms.length ? clanRooms.slice(0, 2) : []),
    [clanRooms]
  );

  const recordList: IRecord[] = useMemo(() => {
    if (roomList.length === 1) {
      return clanRecords.slice(0, 1);
    }

    if (!roomList.length) {
      return clanRecords.slice(0, 2);
    }

    return [];
  }, [clanRecords]);

  const isMobile = isMobileDevice();

  return (
    <div
      className="flex flex-col py-[16px] px-[24px] gap-[12px] bg-dark rounded-[10px] cursor-pointer hover:bg-light"
      onClick={handleNavigateClan}
    >
      <div className="flex justify-between">
        <span className="tpg-b1">{clan}</span>
        <span className="tpg-b2 text-tpg_base">{place}</span>
      </div>
      <div className="flex gap-2">
        <span className="tpg-c1 text-bright_product">{`${clanRooms.length} в эфире`}</span>
        <span className="tpg-c1 text-bright_product">{`${clanRecords.length} в записи`}</span>
      </div>
      {isMobile && (
        <div
          className={cn('grid grid-cols-2 gap-[4px] w-full', {
            'grid-cols-1': breakPoint === 'mobile',
          })}
        >
          {roomList.map(({ livekitName, accountId, src, publisherName }) => (
            <Card
              type="stream"
              key={livekitName}
              cardId={livekitName}
              host={accountId}
              clanId={id}
              src={src}
              publisherName={publisherName}
            />
          ))}
          {recordList.map(
            ({
              id: recordId,
              previewSrc,
              publisherName,
              publisherId,
              tags,
              startedAt,
              endedAt,
              description,
            }) => (
              <Card
                type="record"
                key={recordId}
                cardId={recordId}
                tags={tags}
                clanId={id}
                publisherName={publisherName}
                publisherId={publisherId}
                src={previewSrc}
                startedAt={startedAt}
                endedAt={endedAt}
                description={description}
              />
            )
          )}
        </div>
      )}
      {!isMobile && (
        <div
          className={cn('grid grid-cols-2 gap-[4px] w-full', {
            'grid-cols-1': breakPoint === 'mobile',
          })}
        >
          {clanRooms.map(({ livekitName, accountId, src, publisherName }) => (
            <Card
              type="stream"
              key={livekitName}
              cardId={livekitName}
              host={accountId}
              clanId={id}
              src={src}
              publisherName={publisherName}
            />
          ))}
          {clanRecords.map(
            ({
              id: recordId,
              previewSrc,
              publisherName,
              publisherId,
              tags,
              startedAt,
              endedAt,
              description,
            }) => (
              <Card
                type="record"
                key={recordId}
                cardId={recordId}
                tags={tags}
                clanId={id}
                publisherName={publisherName}
                publisherId={publisherId}
                src={previewSrc}
                startedAt={startedAt}
                endedAt={endedAt}
                description={description}
              />
            )
          )}
        </div>
      )}
    </div>
  );
};

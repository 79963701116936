import {
  IClanRoomsAdapted,
  IClanRoomsResponse,
  IGetRoomResponse,
} from 'api/types/room';
import { IRoom } from 'interfaces';

export const roomAdapter = (data: IGetRoomResponse): IRoom => {
  const {
    livekit_name,
    name,
    num_participants,
    publisher_id,
    publisher_name,
    recording_started,
    active,
  } = data;

  return {
    livekitName: livekit_name,
    roomName: name,
    numParticipants: num_participants,
    accountId: publisher_id,
    publisherName: publisher_name,
    isRecording: recording_started,
    src: '',
    isActive: active,
  };
};

export const getClanRoomsAdapter = (
  data: IClanRoomsResponse
): IClanRoomsAdapted => {
  const { clan_id, rooms } = data;

  return {
    clanId: clan_id,
    rooms: rooms.map(roomAdapter),
  };
};

import { createSlice } from '@reduxjs/toolkit';
import { IDeleteRoomInput } from 'api/types/room';
import { AxiosError } from 'axios';
import { SEPARATOR } from 'constants/livekit';
import { errorMessages, successMessages } from 'constants/messages';
import { reducersNames } from 'constants/reducers';
import { IGroupRooms, IRoomsSlice, TSliceWithPromiseFlags } from 'interfaces';

import { notify } from 'utils';

import {
  createRoomIngressThunk,
  createRoomThunk,
  deleteRoomThunk,
  getClanRoomsThunk,
  getLiveKitTokenThunk,
  getRecordingSignalThunk,
} from './actions';

const initialState: TSliceWithPromiseFlags<IRoomsSlice> = {
  rooms: new Map<string, IGroupRooms>(),
  selectedRoom: null,
  activeRoomsByClanId: [],
  error: '',
  pending: false,
  url: '',
  streamKey: '',
};

const roomsSlice = createSlice({
  name: reducersNames.STREAM,
  initialState,
  reducers: {
    roomHideController(state, { payload: { clanId, livekitName, isHidden } }) {
      const rooms = state.rooms.get(clanId);

      if (rooms) {
        const room = rooms.active.find(
          (item) => item.livekitName === livekitName
        );

        if (room) {
          room.isHidden = isHidden;
        }

        if (state.activeRoomsByClanId) {
          state.activeRoomsByClanId = rooms.active;
        }

        state.rooms.set(clanId, rooms);
      }
    },
    resetHiddenRooms(state) {
      state.rooms.forEach((room) => {
        room.active.forEach((activeRoom) => {
          activeRoom.isHidden = false;
        });
      });
    },
    selectActiveRoomsByClanId(state, { payload: { clanId } }) {
      const rooms = state.rooms.get(clanId)?.active;

      state.activeRoomsByClanId = rooms || [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        getClanRoomsThunk.fulfilled,
        (state, { payload: { clanId, rooms } }) => {
          if (rooms.length) {
            const groupRooms = rooms.reduce<IGroupRooms>(
              (acc, room) => {
                if (room.isActive) {
                  acc.active.push(room);
                } else {
                  acc.archive.push(room);
                }

                return acc;
              },
              { active: [], archive: [] }
            );

            const initialRooms = state.rooms.get(clanId)?.active;

            groupRooms.active.forEach((activeRoom) => {
              const room = initialRooms?.find(
                (initialRoom) =>
                  initialRoom.livekitName === activeRoom.livekitName
              );

              if (room) {
                activeRoom.isHidden = room.isHidden;
              }
            });

            state.rooms.set(clanId, groupRooms);
          } else {
            state.rooms.delete(clanId);
          }

          state.pending = false;
        }
      )
      .addCase(getClanRoomsThunk.pending, (state) => {
        state.pending = true;
      })
      .addCase(getClanRoomsThunk.rejected, (state) => {
        state.pending = false;
      })
      .addCase(
        createRoomThunk.fulfilled,
        (
          state,
          {
            payload: {
              livekitName,
              roomName,
              numParticipants,
              accountId,
              token,
              src,
              isActive,
            },
          }
        ) => {
          state.selectedRoom = {
            livekitName,
            roomName,
            numParticipants,
            accountId,
            clanId: livekitName.split(SEPARATOR)[0],
            src,
            isRecording: false,
            isActive,
            token,
          };

          state.pending = false;
          state.error = null;
        }
      )
      .addCase(createRoomThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(createRoomThunk.rejected, (state) => {
        state.pending = false;
        state.error = errorMessages.CREATE_ROOM;
      })
      .addCase(createRoomIngressThunk.fulfilled, (state, { payload }) => {
        if (payload?.stream_key && payload?.url) {
          state.url = payload.url;
          state.streamKey = payload.stream_key;
          state.pending = false;
          state.error = null;
        }
      })
      .addCase(createRoomIngressThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(createRoomIngressThunk.rejected, (state) => {
        state.pending = false;
        state.error = errorMessages.CREATE_ROOM;
      })
      .addCase(
        getLiveKitTokenThunk.fulfilled,
        (state, { payload: { token, clanId, roomName } }) => {
          const rooms = state.rooms.get(clanId);

          if (rooms) {
            const room = rooms.active.find(
              (item) => item.roomName === roomName
            );

            if (room) {
              room.token = token;
            }

            if (state.activeRoomsByClanId) {
              state.activeRoomsByClanId = rooms.active;
            }

            state.rooms.set(clanId, rooms);
          }
        }
      )
      .addCase(getLiveKitTokenThunk.rejected, (state, { payload }) => {
        const error = payload as AxiosError<{ code: string; message: string }>;

        if (error.response) {
          const { data } = error.response;
          state.error = data.message;
        }
      })
      .addCase(deleteRoomThunk.fulfilled, (state, { payload }) => {
        const { clanId, roomName } = payload as unknown as IDeleteRoomInput;

        if (clanId) {
          const clanRooms = state.rooms.get(clanId);

          if (clanRooms) {
            const { active } = clanRooms;

            state.rooms.set(clanId, {
              ...clanRooms,
              active: active.filter((room) => room.roomName !== roomName),
            });
          }
        }

        state.selectedRoom = null;
      })
      .addCase(
        getRecordingSignalThunk.fulfilled,
        (state, { payload: { isRecording, ...roomInfo } }) => {
          state.selectedRoom = { ...roomInfo, isRecording };

          if (isRecording) {
            notify.success(successMessages.START_RECORDING);
          } else {
            notify.error(errorMessages.START_RECORDING);
          }
        }
      );
  },
});

export const { actions: roomActions, reducer: roomReducer } = roomsSlice;

export * from './selectors';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorMessages, successMessages } from 'constants/messages';
import { reducersNames } from 'constants/reducers';
import { IPlayer } from 'interfaces/player';
import { ID } from 'types/common';

import { notify } from 'utils';

import { banAccountThunk } from '../account/actions';

import { getAllPlayersThunk } from './actions';

interface IPlayerState {
  list: IPlayer[] | null;
  pending: boolean;
  error: string | null;
}

const initialState: IPlayerState = { list: null, pending: false, error: null };

const playerSlice = createSlice({
  name: reducersNames.PLAYER,
  initialState,
  reducers: {
    setPlayers(state, action: PayloadAction<IPlayer[]>) {
      state.list = action.payload;
    },
    addNewPlayer(state, action: PayloadAction<IPlayer>) {
      if (state.list) state.list = [...state.list, action.payload];
    },
    updatePlayer(state, action: PayloadAction<IPlayer>) {
      if (state.list) {
        const index = state.list.findIndex(
          (player) => player.id === action.payload.id
        );

        state.list[index] = action.payload;
      }
    },
    removePlayer(state, action: PayloadAction<ID>) {
      if (state.list)
        state.list = state.list.filter(
          (player) => player.id !== action.payload
        );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllPlayersThunk.fulfilled, (state, action) => {
        state.list = action.payload;
        state.error = null;
        state.pending = false;
      })
      .addCase(getAllPlayersThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(getAllPlayersThunk.rejected, (state) => {
        state.pending = false;
        state.error = errorMessages.LOADING_ERROR;
      })
      .addCase(banAccountThunk.fulfilled, () => {
        notify.success(successMessages.DELETE_PLAYER);
      });
  },
});

export const { actions: playerActions, reducer: playerReducer } = playerSlice;

export * from './selectors';

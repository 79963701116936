import { ElementType, FC, ReactNode, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ELStorageKeys } from 'constants/localStorage';
import { EUserRole, roleWeights } from 'constants/profile';
import { appRoutes, authRoutes, navigation } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'hooks';
import { MainLayout } from 'layouts/MainLayout';
import { AdminScreen } from 'routes/Admin';
import { RightHeaderContent } from 'routes/Admin/components/RightHeaderContent';
import { ClanAdminPanelScreen } from 'routes/ClanAdminPanel';
import { ClansScreen } from 'routes/Clans';
import { HeaderNavigation } from 'routes/Clans/HeaderNavigation';
import { LoggedInScreen } from 'routes/LoggedIn';
import { Login } from 'routes/Login';
import { MapScreen } from 'routes/Map';
import { PlayerAdminPanelScreen } from 'routes/PlayerAdminPanel';
import { RecordScreen } from 'routes/Record';
import { RecordsScreen } from 'routes/RecordsScreen';
import { StreamScreen } from 'routes/Stream';
import { StreamLaunchScreen } from 'routes/StreamLaunch';
import { StreamLaunchAppScreen } from 'routes/StreamLaunchApp';
import { StreamsScreen } from 'routes/Streams';
import { UserStreamsLayout } from 'routes/Streams/UserStreamsLayout';
import { StreamsPanelScreen } from 'routes/StreamsPanel';
import { authActions, authSelector } from 'store';
import { getAccountThunk } from 'store/slices/account/actions';
import { roomActions } from 'store/slices/room';

import { BackButton } from 'components/BackButton';
import { ControlPanelMenu } from 'components/ControlPanelMenu';
import { FilterStreams } from 'components/FilterStreams';
import { FullScreenButton } from 'components/FullScreenButton';
import { NotFound } from 'components/NotFound';
import { ShowAllStreamsButton } from 'components/ShowAllStreamsButton';
import { ShowTileButton } from 'components/ShowTileButton';
import { FilterButton } from 'components/ui/FilterButton';
import { Loader } from 'components/ui/Loader';
import { getToken } from 'utils';
import { getLStorage } from 'utils/helpers/localStorage';

type TRouteProps = {
  path: string;
  component: ReactNode;
  layout: ElementType | null;
  rightHeaderButton?: ReactNode;
  leftContent?: ReactNode;
  centralContent?: ReactNode;
  isPageControlPanel?: boolean;
  isShowHeader?: boolean;
  isStartStream?: boolean;
  resetOptions?: () => void;
};

const authPages = Object.values(authRoutes);

const publicPages = Object.values({
  ...appRoutes,
});

const getProperRedirect = (path: string) => {
  const { role } = useAppSelector(authSelector);

  const accessToken = getToken();

  if (!accessToken && publicPages.includes(path)) {
    return <Navigate to={authRoutes.SIGN_IN} replace />;
  }

  if (accessToken && authPages.includes(path)) {
    return <Navigate to={role ? navigation[role] : '/'} replace />;
  }

  return null;
};

const AppRoute: FC<TRouteProps> = ({
  component,
  layout,
  path,
  rightHeaderButton,
  leftContent,
  centralContent,
  isPageControlPanel,
  isShowHeader,
  isStartStream,
  resetOptions,
}) => {
  const redirect = getProperRedirect(path);

  if (redirect) return redirect;

  if (layout) {
    const Layout = layout;

    return (
      <Layout
        rightHeaderButton={rightHeaderButton && rightHeaderButton}
        centralContent={centralContent && centralContent}
        leftContent={leftContent && leftContent}
        isPageControlPanel={isPageControlPanel}
        isShowHeader={isShowHeader}
        isStartStream={isStartStream}
        resetOptions={resetOptions}
      >
        {component}
      </Layout>
    );
  }

  return <>{component}</>;
};

export const App: FC = (): JSX.Element => {
  const [isShowPanel, setShowPanel] = useState(false);
  const [isOpenFullScreen, setOpenFullScreen] = useState(false);
  const [isOpenFilter, setOpenFilter] = useState(false);
  const [isShowAllStream, setShowAllStream] = useState(true);

  const { role } = useAppSelector(authSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const accountId = getLStorage(ELStorageKeys.accountId);

    if (!accountId) {
      dispatch(authActions.logout());
    } else {
      dispatch(getAccountThunk(accountId));
    }
  }, []);

  useEffect(() => {
    if (!isShowPanel) {
      dispatch(roomActions.resetHiddenRooms());
    }
  }, [isShowPanel]);

  const handleShowPanel = (value: boolean) => {
    setShowPanel(value);
  };

  const handleOpenFullScreen = (value: boolean) => {
    setOpenFullScreen(value);
  };

  const handleShowAllStream = (value: boolean) => {
    setShowAllStream(value);
  };

  const handleClickFilter = (value: boolean) => {
    setOpenFilter(value);
  };

  const handleResetOptions = () => {
    setOpenFilter(false);
    setOpenFullScreen(false);
    setShowPanel(false);
  };

  if (!role) {
    return (
      <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path={authRoutes.SIGN_IN}
        element={
          <AppRoute
            path={authRoutes.SIGN_IN}
            component={<Login />}
            layout={null}
          />
        }
      />
      <Route path={authRoutes.LOGGED_IN} element={<LoggedInScreen />} />
      {roleWeights[role] >= roleWeights.admin && (
        <>
          <Route
            path={appRoutes.ADMIN_NEW_PLAYER}
            element={
              <AppRoute
                path={appRoutes.ADMIN_NEW_PLAYER}
                component={<PlayerAdminPanelScreen isNewPlayer />}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN_CLAN}
            element={
              <AppRoute
                path={appRoutes.ADMIN_CLAN}
                component={<ClanAdminPanelScreen />}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN_PLAYER}
            element={
              <AppRoute
                path={appRoutes.ADMIN_PLAYER}
                component={<PlayerAdminPanelScreen />}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN_NEW_CLAN}
            element={
              <AppRoute
                path={appRoutes.ADMIN_NEW_CLAN}
                component={<ClanAdminPanelScreen isNewClan />}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN}
            element={
              <AppRoute
                path={appRoutes.ADMIN}
                component={<AdminScreen />}
                layout={MainLayout}
                rightHeaderButton={<RightHeaderContent />}
              />
            }
          />
        </>
      )}
      {roleWeights[role] >= roleWeights.moderator && (
        <>
          <Route
            path={appRoutes.CLANS}
            element={
              <AppRoute
                path={appRoutes.CLANS}
                component={
                  isShowPanel || isOpenFullScreen ? (
                    <StreamsPanelScreen
                      onCloseFullScreen={handleOpenFullScreen}
                      onClosePanel={handleShowPanel}
                      onShowAllStream={handleShowAllStream}
                      isOpenFullScreen={isOpenFullScreen}
                      isShowAllStream={isShowAllStream}
                      isClansPage={true}
                    />
                  ) : (
                    <ClansScreen />
                  )
                }
                layout={MainLayout}
                rightHeaderButton={true}
                leftContent={
                  <ControlPanelMenu
                    content={
                      <>
                        <FullScreenButton
                          isActive={isOpenFullScreen}
                          onClick={handleOpenFullScreen}
                        />
                        {/* ToDo: temporarily hide filter by records */}
                        {isShowPanel && (
                          <FilterButton
                            label={
                              isShowPanel
                                ? 'Фильтр по трансляциям'
                                : 'Фильтр по записям'
                            }
                            onClick={handleClickFilter}
                            isOpen={isOpenFilter}
                            content={
                              isShowPanel ? (
                                <FilterStreams />
                              ) : (
                                <span>Фильтр по записям</span>
                              )
                            }
                            leftPosition={isShowPanel ? '205px' : '170px'}
                          />
                        )}
                        {isShowPanel && (
                          <ShowAllStreamsButton
                            isActive={isShowAllStream}
                            onClick={handleShowAllStream}
                          />
                        )}
                        <ShowTileButton
                          isActive={isShowPanel}
                          onClick={handleShowPanel}
                        />
                      </>
                    }
                  />
                }
                isShowHeader={!isOpenFullScreen}
                isStartStream={!isShowPanel && role !== EUserRole.MODERATOR}
                centralContent={
                  !isShowAllStream &&
                  isShowPanel && <HeaderNavigation isAllClans={true} />
                }
                resetOptions={handleResetOptions}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN}
            element={
              <AppRoute
                path={appRoutes.ADMIN}
                component={<ClanAdminPanelScreen />}
                layout={MainLayout}
                rightHeaderButton={role === EUserRole.MODERATOR && <div />}
              />
            }
          />
          <Route
            path={appRoutes.CLAN_RECORDS}
            element={
              <AppRoute
                path={appRoutes.CLAN_RECORDS}
                component={<RecordsScreen />}
                rightHeaderButton={<BackButton />}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={appRoutes.MAP}
            element={
              <AppRoute
                path={appRoutes.MAP}
                component={<MapScreen />}
                layout={MainLayout}
                isPageControlPanel={false}
              />
            }
          />
        </>
      )}
      {roleWeights[role] >= roleWeights[EUserRole.USER] && (
        <>
          {role === EUserRole.USER && (
            <Route
              path={appRoutes.STREAMS}
              element={
                <AppRoute
                  path={appRoutes.STREAMS}
                  component={<UserStreamsLayout />}
                  layout={MainLayout}
                  centralContent={<HeaderNavigation />}
                />
              }
            />
          )}
          <Route
            path={appRoutes.CLAN}
            element={
              <AppRoute
                path={appRoutes.CLAN}
                component={
                  isShowPanel || isOpenFullScreen ? (
                    <StreamsPanelScreen
                      onCloseFullScreen={handleOpenFullScreen}
                      onClosePanel={handleShowPanel}
                      onShowAllStream={handleShowAllStream}
                      isOpenFullScreen={isOpenFullScreen}
                      isShowAllStream={isShowAllStream}
                    />
                  ) : (
                    <StreamsScreen />
                  )
                }
                layout={MainLayout}
                leftContent={
                  <ControlPanelMenu
                    content={
                      <>
                        <FullScreenButton
                          isActive={isOpenFullScreen}
                          onClick={handleOpenFullScreen}
                        />
                        {/* ToDo: temporarily hide filter by records */}
                        {isShowPanel && (
                          <FilterButton
                            label={
                              isShowPanel
                                ? 'Фильтр по трансляциям'
                                : 'Фильтр по записям'
                            }
                            onClick={handleClickFilter}
                            isOpen={isOpenFilter}
                            content={
                              isShowPanel ? (
                                <FilterStreams />
                              ) : (
                                <span>Фильтр по записям</span>
                              )
                            }
                            leftPosition={isShowPanel ? '205px' : '170px'}
                          />
                        )}
                        {isShowPanel && (
                          <ShowAllStreamsButton
                            isActive={isShowAllStream}
                            onClick={handleShowAllStream}
                          />
                        )}
                        <ShowTileButton
                          isActive={isShowPanel}
                          onClick={handleShowPanel}
                        />
                      </>
                    }
                  />
                }
                rightHeaderButton={
                  role !== EUserRole.USER && !isShowPanel ? (
                    <BackButton path="/clans" />
                  ) : (
                    true
                  )
                }
                centralContent={
                  (!isShowAllStream || !isShowPanel) && <HeaderNavigation />
                }
                isShowHeader={!isOpenFullScreen}
                isStartStream={!isShowPanel && role === EUserRole.USER}
                resetOptions={handleResetOptions}
              />
            }
          />
          <Route
            path={appRoutes.RECORD}
            element={
              <AppRoute
                path={appRoutes.RECORD}
                component={<RecordScreen />}
                layout={MainLayout}
                rightHeaderButton={<BackButton />}
              />
            }
          />
          <Route
            path={appRoutes.STREAM}
            element={
              <AppRoute
                path={appRoutes.STREAM}
                component={<StreamScreen />}
                layout={MainLayout}
                rightHeaderButton={<BackButton />}
              />
            }
          />
          {role !== EUserRole.MODERATOR && (
            <>
              <Route
                path={appRoutes.STREAM_LAUNCH}
                element={
                  <AppRoute
                    path={appRoutes.STREAM_LAUNCH}
                    component={<StreamLaunchScreen />}
                    layout={MainLayout}
                    rightHeaderButton={<BackButton />}
                  />
                }
              />
              <Route
                path={appRoutes.STREAM_LAUNCH_APP}
                element={
                  <AppRoute
                    path={appRoutes.STREAM_LAUNCH_APP}
                    component={<StreamLaunchAppScreen />}
                    layout={MainLayout}
                    rightHeaderButton={<BackButton />}
                  />
                }
              />
            </>
          )}
        </>
      )}
      <Route path="/" element={<Navigate replace to={navigation[role]} />} />
      <Route
        path="*"
        element={
          role ? <Navigate replace to={navigation[role]} /> : <NotFound />
        }
      />
    </Routes>
  );
};

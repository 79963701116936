import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAccounts } from 'api/account';
import { setClanMember, TSetClanMemberInput } from 'api/clans';
import { register, TRegisterRequest } from 'api/register';
import { AxiosResponse } from 'axios';
import { errorMessages, successMessages } from 'constants/messages';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import { ThunkAPI } from 'interfaces';
import { IClanPlayer, IPlayer } from 'interfaces/player';

import { createThunk, notify } from 'utils';

import { getClanMembersThunk, setClanMemberThunk } from '../clan/actions';

export const registerThunk = createAsyncThunk<
  AxiosResponse<any, any>,
  TRegisterRequest & { accesses?: IClanPlayer[] }
>(
  `${reducersNames.PLAYER}/${asyncActionsNames.REGISTER}`,
  async (request, thunkAPI) => {
    const { email, password, name, accesses } = request;

    try {
      const data = await register({
        email,
        password,
        name,
      }).then(async (res) => {
        if (accesses?.length) {
          await Promise.all(
            accesses.map((access) =>
              thunkAPI.dispatch(
                setClanMemberThunk({
                  clanId: access.clanId,
                  memberId: res.data.id,
                  roleId: access.roleId,
                  isAddedFromAccount: true,
                  isAddedWithNewAccount: true,
                })
              )
            )
          );
        }

        thunkAPI.dispatch(getAllPlayersThunk());

        notify.success(successMessages.ADD_USER);

        return res;
      });

      return data;
    } catch (error) {
      notify.error(errorMessages.REGISTER_ERROR);

      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const registerWithClanThunk = createAsyncThunk<
  ThunkAPI,
  TRegisterRequest &
    Omit<TSetClanMemberInput, 'memberId'> & {
      isAddedFromClan?: boolean;
    }
>(
  `${reducersNames.PLAYER}/${asyncActionsNames.REGISTER}`,
  async (request, thunkAPI) => {
    const { email, password, name, clanId, roleId, isAddedFromClan } = request;

    try {
      const data = await register({
        email,
        password,
        name,
      }).then(async (res) => {
        const { data } = res;

        await setClanMember({ clanId, roleId, memberId: data.id });

        notify.success(successMessages.ADD_USER);

        return data;
      });

      if (isAddedFromClan) {
        thunkAPI.dispatch(getClanMembersThunk(request.clanId));
      } else {
        thunkAPI.dispatch(getAllPlayersThunk());

        notify.success(successMessages.SAVE_DATA);
      }

      return data;
    } catch (error) {
      notify.error(errorMessages.REGISTER_ERROR);

      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllPlayersThunk = createThunk<IPlayer[]>(
  `${reducersNames.PLAYER}/${asyncActionsNames.GET_ALL_PLAYERS}`,
  getAccounts,
  { errorMessage: errorMessages.GET_ACCOUNTS }
);

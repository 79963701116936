import { FC, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { IAccountThunkData, TAccountEmailThunkData } from 'api/types/account';
import cn from 'classnames';
import { warningMessages } from 'constants/messages';
import { EUserRole } from 'constants/profile';
import { useAppDispatch, useClickOutside } from 'hooks';
import { IOption } from 'interfaces';
import { IPlayer, IUpdatedPlayerData } from 'interfaces/player';
import { roleOptions } from 'mock';
import {
  updateAccountEmailThunk,
  updateAccountPasswordThunk,
  updateAccountRoleThunk,
  updateAccountThunk,
} from 'store/slices/account/actions';
import { ID } from 'types/common';
import { rolesReverseMap } from 'types/player';
import { useDebouncedCallback } from 'use-debounce';

import { CollapseContainer } from 'components/CollapseContainer';
import { CopyIcon } from 'components/CopyIcon';
import { DeleteModal } from 'components/DeleteModal';
import { GeneratePassword } from 'components/GeneratePassword';
import { Button, Select, TextInput } from 'components/ui';
import { maskPassword, notify } from 'utils';

interface InfoTableProps extends IPlayer {
  globalRole: EUserRole;
  onRemove: (id: ID) => void;
  onUpdatePlayer: (value: IUpdatedPlayerData) => void;
  clanId: ID;
  computerOptions: IOption[];
  isNewClan?: boolean;
}

export const InfoTable: FC<InfoTableProps> = ({
  globalRole,
  isNewClan,
  clanId,
  id,
  name,
  // launchedStreams,
  computerOptions,
  external_id,
  password,
  computer,
  // status,
  roles,
  role: playerRole,
  onRemove,
  onUpdatePlayer,
}) => {
  const roleClan = useMemo(
    () => roles.find((role) => role.clan_id === clanId),
    [roles, clanId]
  );

  const rolePlayer = useMemo(
    () => (isNewClan ? playerRole : roleClan?.role_id),
    [isNewClan, playerRole, roleClan]
  );

  const roleInitialValue = roleOptions.find(
    (option) => option.value === rolePlayer
  );

  const [playerName, setPlayerName] = useState(name);
  const [playerLogin, setPlayerLogin] = useState(external_id);
  const [playerPassword, setPlayerPassword] = useState(password);

  const [selectedRole, setSelectedRole] = useState<IOption>(
    roleInitialValue || {
      value: '',
      label: '',
    }
  );

  const [selectedComputer, setSelectedComputer] = useState<IOption>({
    value: '',
    label: '',
  });

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isShowDeleteButton, setShowDeleteButton] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const isAdmin =
    globalRole === EUserRole.ADMIN || globalRole === EUserRole.ROOT;

  useClickOutside<HTMLDivElement, void>(containerRef, () =>
    setShowDeleteButton(false)
  );

  useEffect(() => {
    const computerInitialValue = computerOptions.find(
      (option) => option.value === computer?.id
    );

    if (computerInitialValue) {
      setSelectedComputer(computerInitialValue);
    }
  }, [computerOptions, computer]);

  // const handleNavigate = (event: MouseEvent) => {
  //   event.stopPropagation();

  //   navigate(`/admin-panel/clan/records`, {
  //     state: {
  //       clanId,
  //     },
  //   });
  // };

  const handleRightClick = (e: MouseEvent) => {
    e.preventDefault();

    setShowDeleteButton(true);
  };

  const sendingRequest = useDebouncedCallback((value, requestFunction) => {
    requestFunction(value);
  }, 300);

  const handleChangeName = (value: string) => {
    setPlayerName(value);

    if (isNewClan) {
      onUpdatePlayer({
        id,
        name: value,
      });
    } else {
      sendingRequest({ id, clanId, name: value }, (value: IAccountThunkData) =>
        dispatch(updateAccountThunk(value))
      );
    }
  };

  const handleChangeLogin = (value: string) => {
    setPlayerLogin(value);

    if (isNewClan) {
      onUpdatePlayer({
        id,
        login: value,
      });
    } else {
      if (playerPassword) {
        sendingRequest(
          { id, email: value, current_password: playerPassword },
          (value: TAccountEmailThunkData) =>
            dispatch(updateAccountEmailThunk(value))
        );
      } else {
        notify.warning(warningMessages.UPDATE_LOGIN, {
          autoClose: 3000,
        });
      }
    }
  };

  const handleSelectRole = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setSelectedRole(value);

      if (isNewClan) {
        onUpdatePlayer({
          id,
          role: rolesReverseMap[value.value],
        });
      } else {
        dispatch(
          updateAccountRoleThunk({
            account_id: String(id),
            clan_id: String(clanId),
            role_id: value.value,
          })
        );
      }
    }
  };

  const handleSelectComputer = (value: IOption | IOption[]) => {
    if (!Array.isArray(value) && !isNewClan) {
      setSelectedComputer(value);

      if (!isNewClan) {
        dispatch(updateAccountThunk({ id, clanId, computer_id: value.value }));
      }
    }
  };

  const handleOpenModal = (event: MouseEvent) => {
    event.stopPropagation();

    setOpenDeleteModal(true);
  };

  const handleCloseModal = () => {
    setOpenDeleteModal(false);
  };

  const handleRemovePlayer = () => {
    onRemove(id);

    handleCloseModal();
  };

  const handleGeneratePassword = (value: string) => {
    setPlayerPassword(value);

    if (!isNewClan) {
      dispatch(
        updateAccountPasswordThunk({
          id: String(id),
          password: value,
          password2: value,
        })
      );
    }
  };

  return (
    <div
      ref={containerRef}
      className={cn({ 'cursor-pointer': isAdmin })}
      onContextMenu={handleRightClick}
    >
      <CollapseContainer
        hidingBlock={
          <>
            {isAdmin ? (
              <Select
                value={selectedComputer}
                options={computerOptions}
                onSelect={handleSelectComputer}
                placeholder="Техника"
                className="pl-[16px] border-t border-solid border-t-dark_product"
              />
            ) : (
              <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
                <span className="tpg-c2 text-tpg_light">Техника</span>
                <span className="tpg-c1 pointer-events-none">
                  {selectedComputer.label || 'Не выбрана'}
                </span>
              </div>
            )}
            <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
              <span className="tpg-c2 text-tpg_light">Логин</span>
              {isAdmin ? (
                <div className="flex flex-row items-center gap-[8px]">
                  <TextInput
                    value={playerLogin}
                    inputClassName="bg-ultrablack h-[20px] !pl-0"
                    className="w-full"
                    onChange={handleChangeLogin}
                  />
                  <CopyIcon value={external_id} />
                </div>
              ) : (
                <span className="tpg-c1 pointer-events-none">
                  {playerLogin}
                </span>
              )}
            </div>
            <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
              <span className="tpg-c2 text-tpg_light">Пароль</span>
              <div className="flex flex-row justify-between items-center">
                <span className="tpg-c1 pointer-events-none">
                  {maskPassword(playerPassword || 'password')}
                </span>
                {isAdmin && (
                  <div className="flex flex-row gap-[8px]">
                    <GeneratePassword onClick={handleGeneratePassword} />
                    {playerPassword && <CopyIcon value={playerPassword} />}
                  </div>
                )}
              </div>
            </div>
          </>
        }
      >
        <div className="relative h-[61px] flex flex-col px-[16px] py-[10px]">
          <span className="tpg-c2 text-tpg_light">Пользователь</span>
          {isAdmin ? (
            <TextInput
              value={playerName}
              inputClassName="bg-ultrablack h-[20px] !pl-0"
              onChange={handleChangeName}
            />
          ) : (
            <span className="tpg-c1 pointer-events-none">{playerName}</span>
          )}
          {isShowDeleteButton && isAdmin && (
            <Button
              className="absolute left-[140px] top-[15px] rounded-[10px] border border-solid border-tpg_light bg-ultrablack px-[16px] py-[6px] text-tpg_base hover:bg-dark_product"
              title="Удалить"
              onClick={handleOpenModal}
            />
          )}
        </div>
        {/* TODO: return when status data is ready */}
        {/* <div className="relative flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
          <span className="tpg-c2 text-tpg_light">Статус</span>
          <span className="tpg-c1 pointer-events-none">
            {status || 'Активный'}
          </span> 
        </div> */}
        {isAdmin ? (
          <Select
            value={selectedRole}
            options={roleOptions}
            onSelect={handleSelectRole}
            placeholder="Роль"
            className="pl-[16px] border-t border-solid border-t-dark_product"
          />
        ) : (
          <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
            <span className="tpg-c2 text-tpg_light">Роль</span>
            <span className="tpg-c1 pointer-events-none">
              {selectedRole.label || 'Не выбран'}
            </span>
          </div>
        )}
        {/* TODO: return when launched streams data is ready */}
        {/* <div className="flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
          <span className="tpg-c2 text-tpg_light">Записанных эфиров</span>
          <div className="flex flex-row justify-between items-center ">
            <span className="tpg-c1 pointer-events-none">
              {launchedStreams ?? 0}
            </span>
            {launchedStreams && (
              <span
                className="tpg-c2 text-bright_product hover:text-main_product cursor-pointer"
                onClick={handleNavigate}
              >
                Перейти к записям
              </span>
            )}
          </div>
        </div> */}
      </CollapseContainer>
      {isOpenDeleteModal && (
        <DeleteModal
          title="Вы уверены, что хотите удалить пользователя?"
          onClose={handleCloseModal}
          onRemove={handleRemovePlayer}
        />
      )}
    </div>
  );
};

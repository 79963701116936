import { FC } from 'react';
import { useParticipants } from '@livekit/components-react';

import { getWordDayForm } from 'utils';

export const Presence: FC = () => {
  const participants = useParticipants();

  const presencePluralString = getWordDayForm(participants.length, [
    'зритель',
    'зрителя',
    'зрителей',
  ]);

  return <span className="tpg-h4">1 зритель</span>;
};

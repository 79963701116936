import { Key, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IClan } from 'interfaces/clan';
import { clansSelector } from 'store';
import { getClansThunk } from 'store/slices/clan/actions';

import { Loader } from 'components/ui/Loader';
import STable from 'components/ui/Table/STable';
import { STableCell } from 'components/ui/Table/STableCell';
import { STableHeadCell } from 'components/ui/Table/STableHeader';

const ENTITIES_HEAD_CELLS: STableHeadCell<IClan>[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Подразделение',
  },
  {
    id: 'users_count',
    numeric: false,
    disablePadding: false,
    label: 'Количество пользователей',
  },
  {
    id: 'work_area',
    numeric: false,
    disablePadding: false,
    label: 'Район работы',
  },
  {
    id: 'recordings_count',
    numeric: false,
    disablePadding: false,
    label: 'Записей',
  },
];

export const ClansTable = () => {
  const { clans, pending } = useAppSelector(clansSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getClansThunk());
  }, []);

  const fetchRows = async () => {
    const response: {
      documents: IClan[];
      total_count: number;
    } = await new Promise((resolve) =>
      resolve({
        total_count: clans?.length || 0,
        documents: clans || [],
      })
    );

    return {
      rows: response.documents,
      total: response.total_count,
    };
  };

  const navigate = useNavigate();

  const handleRowClick = (_row: IClan, rowId: Key) => {
    navigate(`/admin-panel/clan/${rowId}`);
  };

  if (pending)
    return (
      <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center">
        <Loader />
      </div>
    );

  return (
    <STable
      headCells={ENTITIES_HEAD_CELLS}
      rowIdExtractor={(row) => row?.id}
      fetchRows={fetchRows}
      handleRowClick={handleRowClick}
      classNames={{
        root: 'group !h-[calc(100vh-148px)]',
        row: 'group hover:!bg-ultrablack',
        cell: 'group-hover:!bg-[inherit] !border-b-tpg_light !bg-light',
      }}
      renderRowCells={(row, props) => (
        <>
          <STableCell {...props} key={row.name} propName="name" cellType="text">
            {row.name}
          </STableCell>
          <STableCell
            {...props}
            key={row.users_count}
            propName="users_count"
            cellType="text"
          >
            {row.users_count}
          </STableCell>
          <STableCell
            {...props}
            key={row.work_area}
            propName="work_area"
            cellType="text"
          >
            {row.work_area || 'Не указан'}
          </STableCell>
          <STableCell
            {...props}
            key={row.recordings_count}
            propName="recordings_count"
            cellType="text"
          >
            {row.recordings_count || 0}
          </STableCell>
        </>
      )}
    />
  );
};

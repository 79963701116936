import { FC, MouseEvent, useRef, useState } from 'react';
import { LiveKitRoom } from '@livekit/components-react';
import { useAppDispatch, useClickOutside } from 'hooks';
import { roomActions } from 'store/slices/room';

import { ViewerPlayer } from 'components/StreamPlayerContainer/components/ViewerPlayer';
import { Button } from 'components/ui';

interface RoomProps {
  livekitName: string;
  publisherName: string;
  clanId?: string;
  clanName?: string;
  token?: string;
}

export const Room: FC<RoomProps> = ({
  livekitName,
  publisherName,
  clanName,
  clanId,
  token,
}) => {
  const [isShowHiddenButton, setShowHiddenButton] = useState(false);

  const dispatch = useAppDispatch();

  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside<HTMLDivElement, void>(containerRef, () =>
    setShowHiddenButton(false)
  );

  const handleContextMenu = (e: MouseEvent) => {
    e.preventDefault();

    setShowHiddenButton(true);
  };

  const handleHideRoom = (livekitName: string) => {
    dispatch(
      roomActions.roomHideController({ livekitName, clanId, isHidden: true })
    );
  };

  return (
    <div
      ref={containerRef}
      className="relative"
      onContextMenu={handleContextMenu}
    >
      <LiveKitRoom token={token} serverUrl={process.env.REACT_APP_LIVEKIT_API}>
        <ViewerPlayer isMuted={true} publisherName={publisherName} />
      </LiveKitRoom>
      <div className="absolute top-[8px] left-[16px] flex flex-row gap-[8px] pointer-events-none">
        <div className="px-[12px] py-[5px] bg-ultrablack rounded-[5px]">
          <span className="tpg-c2">{publisherName}</span>
        </div>
        {clanName && (
          <div className="px-[12px] py-[5px] bg-ultrablack rounded-[5px]">
            <span className="tpg-c2">{clanName}</span>
          </div>
        )}
      </div>
      {isShowHiddenButton && (
        <div className="absolute z-[1] left-[50%] translate-x-[-50%] top-[50%]">
          <Button
            className="bg-ultrablack hover:bg-bright border border-solid border-tpg_light"
            title="Скрыть"
            onClick={() => handleHideRoom(livekitName)}
          />
        </div>
      )}
    </div>
  );
};
